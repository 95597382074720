import React, { useState, useEffect, useContext } from "react";

import './DownloadBrochuer.css'
import DownloadLotti from './Downlod-animation/DownloadLotti';
import { Context } from '../../utils/context';
const DownloadBrochuer = () => {
    const { getData, getDownloadDataPDF, } = useContext(Context);

    const [data, setData] = useState([]);
    const [length, setLength] = useState(1);
    const [selectedFiles, setSelectedFiles] = useState([]);

    // const getDataAll = async () => {
    //     try {
    //         const response = await getData(`/without-login/download/download`);
    //         setData(response.data);
    //         setLength(response.data.length)
    //         console.log(response.data);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };
    const DemoDownload = async () => {
        await getDownloadDataPDF(
            "/without-login/download-sample/download",
            "Broucher Download",

        );
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const newSelectedFiles = Array.from(files).map((file) => URL.createObjectURL(file));
            setSelectedFiles(newSelectedFiles);
        }
    };
    useEffect(() => {
        const fetchData = async () => {

        };
        fetchData();
    }, [0]);
    return (
        <>
            <section className='download-brochuer'>
                <div className='container'>
                    <div className='row back-iamge'>
                        <div className='download-heading mb-5 text-center'>
                            <DownloadLotti />
                            <h5>Download Brochure</h5>
                            <p>To access detailed information about our products/services, please click the link below to download the brochure.</p>
                        </div>

                        <div className='button-holder mb-5 text-center'>
                            <input
                                id="download"
                                type="file"
                                multiple
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <button className='btn bth-download'
                                onClick={DemoDownload}
                            >
                                Download
                            </button>
                            {selectedFiles.length > 0 && (
                                <div id="selectedImagesContainer" style={{ display: 'block' }}>
                                    {selectedFiles.map((url, index) => (
                                        <img key={index} src={url} alt={`Selected Image ${index + 1}`} style={{ maxWidth: '200px' }} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DownloadBrochuer
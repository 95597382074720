import React from "react";
import "../AboutUs.css";
const OurStrength = () => {
  return (
    <>
      <section className="our-strength">
        <div className="container">
          <div className="row">
            <div className="heading-about text-center ">
              <h4>Our Strengths</h4>
            </div>
            <div className="d-flex justify-content-center mb-5">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <div className="row line-row">
                <div className="col-xxl-1 col-xl-1 ">
                  <div className="hover-circle card-effect3">
                    <div className="circle-name  mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-0">
                      <p>Commitment to excellence</p>
                    </div>
                    <div className="circle-holder ">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/hand-shake.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="up-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/up-vi.png"
                          }
                          className="upline-img"
                        />
                      </div>
                      <div>
                        <div class="vl"></div>
                      </div>
                    </div>
                    <div className="commit-name text-center">
                      <p>
                        Commitment to <br /> excellence
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle">
                    <div className="circle-holder1">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/direct-marketing.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="down-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/line-vi.png"
                          }
                          className="downline-img"
                        />
                      </div>
                      <div className="circle-name1  ">
                        <p>
                          Quality and
                          <br /> commitment towards
                          <br /> after-sales service
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle card-effect3">
                    <div className="circle-name name-2  mt-5">
                      <p className="customer-desktop">Consistent quality</p>
                    </div>
                    <div className="circle-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/consistency.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="up-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/up-vi.png"
                          }
                          className="upline-img"
                        />
                      </div>
                    </div>
                    <div className="commit-name1 text-center">
                      <p>Consistent quality</p>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle">
                    <div className="circle-holder1">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/range.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="down-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/line-vi.png"
                          }
                          className="downline-img"
                        />
                      </div>
                      <div className="circle-name1  ">
                        <p>
                          One stop shop
                          <br /> with very wide
                          <br /> range of products
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle card-effect card-effect3">
                    <div className="circle-name name-4  mt-5">
                      <p>Next Genertion Technology</p>
                    </div>
                    <div className="circle-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/training.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="up-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/up-vi.png"
                          }
                          className="upline-img"
                        />
                      </div>
                    </div>
                    <div className="commit-name3 text-center">
                      <p>Dedicated workforce</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle">
                    <div className="circle-holder1">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/business.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="down-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/line-vi.png"
                          }
                          className="downline-img"
                        />
                      </div>
                      <div className="circle-name1  ">
                        <p>
                          Strategic alliance
                          <br /> with reputed
                          <br /> companies
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle card-effect card-effect3">
                    <div className="circle-name name-4  mt-5">
                      <p>Dedicated workforce</p>
                    </div>
                    <div className="circle-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/training.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="up-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/up-vi.png"
                          }
                          className="upline-img"
                        />
                      </div>
                    </div>
                    <div className="commit-name3 text-center">
                      <p>Dedicated workforce</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle card-effect1">
                    <div className="circle-holder1">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/positive-review.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="down-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/line-vi.png"
                          }
                          className="downline-img"
                        />
                      </div>
                      <div className="circle-name1  ">
                        <p>
                          Quality and
                          <br /> commitment towards
                          <br /> after-sales service
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1  ">
                  <div className="hover-circle card-effect2 card-effect3">
                    <div className="circle-name name-5  mt-5">
                      <p className="customer-desktop">
                        Customer <br /> focus
                      </p>
                    </div>

                    <div className="circle-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/icon/circle.png"
                        }
                        className="circle-img"
                      />
                      <div className="imag-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/target-audience.png"
                          }
                          className="hand-img"
                        />
                      </div>
                      <div className="up-line">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icon/up-vi.png"
                          }
                          className="upline-img"
                        />
                      </div>
                    </div>
                    <div className="commit-name4 text-center">
                      <p className="customer">Customer focus</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurStrength;

import React, { useState, useEffect, useRef, useContext } from "react";

import { Button, Modal } from "react-bootstrap";
import "./QuickEnquiry.css";
import { Context } from "../../utils/context";
// import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Submit from "./Submit/Submit";

const QuickEnquiry = () => {
  const { postData } = useContext(Context);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile_number: "",
    address: "",
    query: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    name: "",
    email: "",
    mobile_number: "",
    address: "",
    query: "",
  });

  const [modalShow, setModalShow] = React.useState(false);

  const handleShow = () => {
    setModalShow(true);

    setTimeout(() => {
      window.location = "/";
      setModalShow(false);
    }, 3000);
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationMessages = {};

    // Perform validation checks and update validation messages
    if (!formData.name) {
      newValidationMessages.name = "Name is required";
      isValid = false;
    } else if (!(namevalidate)(formData.name)) {
      newValidationMessages.name = "Enter Valid Name";
      isValid = false;
    }

    // Validate email
    if (!formData.email) {
      newValidationMessages.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newValidationMessages.email = "Invalid email format";
      isValid = false;
    }

    // Validate mobile number
    if (!formData.mobile_number) {
      newValidationMessages.mobile_number = "Mobile Number is required";
      isValid = false;
    } else if (!validateMobileNumber(formData.mobile_number)) {
      newValidationMessages.mobile_number = "Invalid mobile number";
      isValid = false;
    }

    // Add more validation checks for other fields...
    if (!formData.address) {
      newValidationMessages.address = "Address is required";
      isValid = false;
    }

    if (!formData.query) {
      newValidationMessages.query = "Write Your Query !!!";
      isValid = false;
    }

    setValidationMessages(newValidationMessages);
    return isValid;
  };
 

  const validateEmail = (email) => {
    const emailRegex = /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return emailRegex.test(email);
  };
  const namevalidate = (name) => {
    const emailRegex = /^[a-zA-Z\s]*$/;
    return emailRegex.test(name);
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      // If validation fails, return early
      return;
    }

    try {
      const response = await postData(
        "/without-login/home/quick-inquiry",
        formData
      );


      if (response?.success) {
        setModalShow({ code: response.code, message: response.message });
        setTimeout(() => {
          window.location = "/";
          setModalShow(false);
        }, 3000);
      } else {
        console.error("Submission failed:", response?.error);
        // Handle submission failure
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle submission error
    }
  };

  return (
    <>
      <section className="enquiry">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="enquiry-heading">
                <h5>
                  Quick <br /> Inquiry
                </h5>
                <p>
                  For any additional information or clarifications, feel free to
                  reach out to us for a quick inquiry.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="Name"
                      placeholder="Enter Name"
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                    <p className="text-danger">{validationMessages.name}</p>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridemail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                    <p className="text-danger">{validationMessages.email}</p>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      maxLength={10}
                      onKeyPress={handleKeyPressContact}
                      onChange={(e) => {


                        setFormData({
                          ...formData,
                          mobile_number: e.target.value,
                        });
                      }}
                    />
                    <p className="text-danger">
                      {validationMessages.mobile_number}
                    </p>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="Address"
                      placeholder="Enter Address"
                      onChange={(e) =>
                        setFormData({ ...formData, address: e.target.value })
                      }
                    />
                    <p className="text-danger">{validationMessages.address}</p>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Query</Form.Label>
                    <Form.Control
                      className="textarea-holder"
                      as="textarea"
                      rows={3}
                      placeholder="Enter Query"
                      onChange={(e) =>
                        setFormData({ ...formData, query: e.target.value })
                      }
                    />
                    <p className="text-danger">{validationMessages.query}</p>
                  </Form.Group>
                </Row>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>

                  <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    centered
                    className="Home-PopUp"
                  >
                    <Modal.Body>
                      <div className="circle">
                        <Submit />
                      </div>
                      <h1 className="successfully">
                        Your Quick Inquiry has been Sent Successfully.{" "}
                      </h1>
                      <h1 className="Thanks">Thank You!</h1>
                    </Modal.Body>
                  </Modal>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QuickEnquiry;

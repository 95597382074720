import React, { useState, useEffect, useContext } from "react";
import "../AboutUs.css";
import Mission from "./Animation/Mission";
import Vision from "./Animation/Vision";
import { Context } from "../../utils/context";
import parse from "html-react-parser";
const Objective = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/perspective/all-perspective`
    );
    setData(response.data); // Set data to an empty array if response is undefined

  };
  const limitLines = (content, lineLimit = 7) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Extract text content
    const textContent = tempDiv.textContent || tempDiv.innerText || '';

    // Split into lines and limit to the specified number
    const lines = textContent.split('\n').slice(0, lineLimit);

    // Join lines back together with line breaks
    return lines.join('\n');
  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  return (
    <>
      <section className="objective-section">
        <div className="container">
          <div className="row bg-image">
            <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-4 my-auto">
              <div className="heading-holder ms-3  ps-2">
                <h5>Perspective</h5>
                <h6>And</h6>
                <h5>Objective</h5>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
              <div className="card">
                <div className="mission ">
                  <Mission />
                </div>
                <div className="heading-about text-center mt-5">
                  <h4>Mission</h4>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="border-red"></div>
                  <div className="border-line "></div>
                </div>
                {data
                  .filter((item) => item.name === 'Mission')
                  .map((item, index) => (
                    <div className="text-center text-holder my-3" key={index}>

                      <p>{(parse(item.description))}</p>
                    </div>
                  ))}
              </div>
            </div>



            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 mx-auto">
              <div className="card ">
                <div className="mission">
                  <Vision />
                </div>
                <div className="heading-about text-center mt-5">
                  <h4>Vision</h4>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="border-red1"></div>
                  <div className="border-line1 "></div>
                </div>
                {data
                  .filter((item) => item.name === 'Vision')
                  .map((item, index) => (
                    <div className="text-center text-holder my-3" key={index}>

                      <p>{(parse(item.description))}</p>
                    </div>
                  ))}
              </div>
            </div>

          </div>
          <hr />
        </div>
      </section>
    </>
  );
};

export default Objective;

import React, { useState, useRef, useContext } from "react";
import "./Complaint.css";
import Form from "react-bootstrap/Form";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ComplainLottie from "./Animation-Complaint/ComplainLottie";
import { Context } from "../utils/context";
library.add(fas);

const Complaint = () => {
  const [show, setShow] = useState(false);
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    product_name: "",
    complaint_reson: "",
    complaint_details: "",
  });
  const timerRef = useRef(null);

  console.log(formData)

  const handleClose = () => {
    setShow(false);

    // Clear the timer when the modal is closed
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleShow = async (e) => {
    e.preventDefault();

    // Validate the form fields
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await postData(
          "/without-login/complaint/complaint",
          formData
        );

        if (response?.success) {
          setShow(true);

          // Set up the timer when the component is shown
          timerRef.current = setTimeout(() => {
            window.location = "/";
            handleClose();
          }, 3000);
        } else {
          alert("Failed to submit the form. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred. Please try again later.");
      }
    } else {
      // Set the validation errors in the state
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    const newValidationMessages = {};


    // Validate name
    if (!formData.name || !formData.name.trim()) {
      errors.name = "Customer Full Name is required";
    }
    else if (!/^[a-zA-Z\s]*$/.test(formData.name.trim())) {
      errors.name = "Enter Valid Name";
    }


    // Validate phone
    if (!formData.phone || !formData.phone.trim()) {
      errors.phone = "Phone Number is required";
    }


    // Validate product name
    if (!formData.product_name || !formData.product_name.trim()) {
      errors.product_name = "Product Name is required";
    }

    // Validate complaint reason
    if (!formData.complaint_reson || !formData.complaint_reson.trim()) {
      errors.complaint_reson = "Reason For Complaint is required";
    }

    // Validate complaint details
    if (!formData.complaint_details || !formData.complaint_details.trim()) {
      errors.complaint_details = "Complaint Details are required";
    }

    return errors;
  };

  return (
    <>
      <section className="complaint">
        <div className="imag-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mx-auto  ">
              <div className="form-holder ">
                <div className="heading-about text-center ">
                  <h4>Complaint Form</h4>
                </div>
                <div className="d-flex justify-content-center mb-5 ">
                  <div className="border-red"></div>
                  <div className="border-line "></div>
                </div>
                <Form>
                  <Row>
                    <div className="col-lg-6 col-md-6 pe-3 pe-md-5">
                      <Form.Group className="mb-3 " controlId="name">
                        <Form.Label>Customer Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder=" "
                          className={`input-mandatory ${errors.name ? "is-invalid" : ""
                            }`}
                        />
                        {errors.name && (
                          <span className="invalid-feedback">
                            {errors.name}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 col-md-6 ">
                      <Form.Group className="mb-3" controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          placeholder=" "
                          onInput={(e) => {
                            // Remove non-numeric characters
                            e.target.value = e.target.value.replace(/\D/g, "");

                            // Limit to 10 digits
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10);
                            }
                          }}
                          className={`input-mandatory ${errors.phone ? "is-invalid" : ""
                            }`}
                        />
                        {errors.phone && (
                          <span className="invalid-feedback">
                            {errors.phone}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </Row>

                  <Form.Group className="mb-3" controlId="product_name">
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="product_name"
                      value={formData.product_name}
                      onChange={handleChange}
                      placeholder=" "
                      className={`input-mandatory ${errors.product_name ? "is-invalid" : ""
                        }`}
                    />
                    {errors.product_name && (
                      <span className="invalid-feedback">
                        {errors.product_name}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="complaint_reson">
                    <Form.Label>Reason For Complaint</Form.Label>
                    <Form.Control
                      type="text"
                      name="complaint_reson"
                      value={formData.complaint_reson}
                      onChange={handleChange}
                      placeholder=" "
                      className={`input-mandatory ${errors.complaint_reson ? "is-invalid" : ""
                        }`}
                    />
                    {errors.complaint_reson && (
                      <span className="invalid-feedback">
                        {errors.complaint_reson}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="complaint_details">
                    <Form.Label>Please provide any Details</Form.Label>
                    <Form.Control
                      type="text"
                      name="complaint_details"
                      value={formData.complaint_details}
                      onChange={handleChange}
                      placeholder=" "
                      className={`input-mandatory ${errors.complaint_details ? "is-invalid" : ""
                        }`}
                    />
                    {errors.complaint_details && (
                      <span className="invalid-feedback">
                        {errors.complaint_details}
                      </span>
                    )}
                  </Form.Group>
                  <div className="button-holder text-center mt-5 ">
                    <Button variant="dark" onClick={handleShow}>
                      Submit
                    </Button>
                  </div>
                  <Modal
                    // show={show}
                    show={show}
                    onHide={handleClose}
                    className="complain-modal"
                    keyboard={false}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Body>
                      <div className="circle">
                        <ComplainLottie />
                      </div>
                      <div className="succesful-text">
                        <p>Your Complaint has been Sent Successfully </p>
                      </div>
                      <div className="thank-text">
                        <p>Thank You!</p>
                      </div>
                    </Modal.Body>
                  </Modal>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Complaint;

import React, { useState, useEffect, useContext } from "react";
import "./Become.css";
import CountUp from "react-countup";
import { Context } from "../../utils/context";

const BEcome = () => {
  const { getData } = useContext(Context);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState(0);
  const [data3, setData3] = useState(0);
  const [productCount, setProductCount] = useState(0);

  const getProduct = async () => {
    try {
      const response = await getData(`/without-login/home-become/all-product`);
      setData(response.data);
      setProductCount(response.data.length);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const getBrand = async () => {
    try {
      const response = await getData(`/without-login/home-become/all-brands`);
      setData2(response.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const getHappyCustomers = async () => {
    try {
      const response = await getData(
        `/without-login/happy-customers/all-happy-customers`
      );
      setData3(response.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProduct();
      await getBrand();
      await getHappyCustomers();
    };
    fetchData();
  }, []);

  return (
    <section className="become">
      <div className="container">
        <div className="bg-image">
          <div className="imag-overlay"></div>
          <div className="row ">
            <div className="col-lg-6 col-md-5">
              <div className="partner-heading">
                <h4>Become Part Of</h4>
                <p>The Largest B2B Platform In India</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4">
              <div className="total-holder text-center">
                <h4>
                  {data3 &&
                    data3.map((item, index) => (
                      <CountUp
                        key={index}
                        start={0}
                        end={item.name}
                        duration={4}
                        decimals={0}
                      />
                    ))}
                  +
                </h4>
                <p>Happy Customers</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-4">
              <div className="total-holder text-center">
                <h4>
                  <CountUp
                    start={0}
                    end={data2?.count}
                    duration={4}
                    decimals={0}
                  />{" "}
                  +
                </h4>

                <p>Brands</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-4">
              <div className="total-holder text-center">
                <h4>
                  <CountUp
                    start={0}
                    end={data?.count}
                    duration={4}
                    decimals={0}
                  />{" "}
                  +
                </h4>
                <p>Products</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BEcome;

import React from "react";
import "./App.css";
import "./index.css";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import {BrowserRouter as Router , Route , Link} from 'react-router-dom';

import { Route, Routes } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import Aboutus from "./about-us/Aboutus";
import TermsConditon from "./terms-condition/TermsConditon";
import PrivacyPolicy from "./terms-condition/PrivacyPolicy";
import ContactUs from "./contact-us/ContactUs";
import Complaint from "./complaint/Complaint";
import OurClient from "./our-client/OurClient";
import Application from "./application/Application";
import CctvInnerpage from "./Products/cctv-innerpage/CctvInnerpage";
import CCTVsystem from "./Products/CCTVsystem";
import AIDC from "./Products/AIDC";
import FireSystem from "./Products/FireSystem";
import PASystem from "./Products/PASystem";
import AccessControl from "./Products/AccessControl";
import IntrusionSystem from "./Products/IntrusionSystem";
import CarParking from "./Products/CarParking";
import MetalDetection from "./Products/MetalDetection";
import DataSolution from "./Products/DataSolution";
import HomeAutomation from "./Products/HomeAutomation";
import CurrencyCounting from "./Products/CurrencyCounting";
import DigitalNetwork from "./Products/DigitalNetwork";
import ScrollToTop from "./Scroll-to-top/ScrollToTop";
import SupportPage from "./SupportPage/SupportPage";
import News from "./News/News";
import NewsDetails from "./NewsDetails/NewsDetails";
import Profile from "./director-profile/Profile";

function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/terms-condition" element={<TermsConditon />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/complaint" element={<Complaint />} />
        <Route path="/our-client" element={<OurClient />} />
        <Route path="/application" element={<Application />} />
        <Route path="/cctv-innerpage/:id" element={<CctvInnerpage />} />
        <Route path="/cctv-system/:id" element={<CCTVsystem />} />
        <Route path="/aidc-pos" element={<AIDC />} />
        <Route path="/fire-system" element={<FireSystem />} />
        <Route path="/pa-system" element={<PASystem />} />
        <Route path="/access-control-system" element={<AccessControl />} />
        <Route path="/intrusion-system" element={<IntrusionSystem />} />
        <Route path="/car-parking-system" element={<CarParking />} />
        <Route path="/metal-detection" element={<MetalDetection />} />
        <Route path="/data-solution" element={<DataSolution />} />
        <Route path="/home-automation" element={<HomeAutomation />} />
        <Route path="/currency-counting" element={<CurrencyCounting />} />
        <Route path="/digital-network" element={<DigitalNetwork />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/news-details" element={<News />} />
        <Route path="/details/:id" element={<NewsDetails />} />

        <Route path="/director-profile" element={<Profile />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import React from "react";
import "../Products/Products.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const AccessControl = ({ title, imageSrc, description }) => {
  const AccessControlDetails = [
    {
      headingname: "IP Controller",
      detailsname:"An IP control system uses the internet and Internet Protocol to transmit data and enable devices..... ",
      imageSource:process.env.PUBLIC_URL + "assets/images/Products/access-1.png",
    },
    {
      headingname: "Reader",
      detailsname:"there are two types of access control key card readers — proximity and magnetic With......",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/access-2.png",
    },
    {
      headingname: "Vehicle Bareer",
      detailsname:"A vehicle barrier acts as a physical boundary that can slow or completely stop vehicles in areas ......",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/access-3.png",
    },
    {
      headingname: "Locking Device",
      detailsname:"A locking device is a mechanical component that prevents mated shafts and other machine .....",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/access-4.png" ,
    },
    {
      headingname: "Softwarez",
      detailsname:"Access control is a fundamental component of data security that dictates who's allowed to .........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/access-5.png",
    },
  ];
  return (
    <>
      <section className="CCTV-System">
        <div className="container">
          <div className="Heading-cctv">
            <div className="heading-about text-center">
              <h4>Access Control System</h4>
            </div>
            <div className="borders-two d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
          </div>

          <div className="row justify-content-center">
            {AccessControlDetails.map((item, index) => (
              <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12 mt-4 mt-xl-5 g-5">
                <Card className="IR-Camera mb-3">
                  <Card.Img variant="top" />
                  <div className="Heading-Main">
                    <h1 className="dome-camera">{item.headingname}</h1>
                  </div>

                  <div className="camera-border"></div>
                  <div className="IR-camera-img">
                    <img src={item.imageSource} className="camera-img" />
                  </div>
                  <Card.Body className="IR-Camera-body">
                    <Card.Text>{item.detailsname}</Card.Text>
                    <div className="read-button">
                      <Button variant="read">Read More</Button>
                      <div className="read-border"></div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AccessControl;

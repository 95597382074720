import React, { useState, useEffect, useContext } from "react";
import "./Application.css";
import { Context } from "../utils/context";
import parse from "html-react-parser";
const Application = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/application/all-application`
    );
    setData(response.data); // Set data to an empty array if response is undefined

  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  // const ApplicationDetails = [
  //   {
  //     headingname: "Commercial",
  //     detailsname:
  //       "Commercial Headquarters, Malls, Multiplexes, IT/ ITES, BPOs, Banks, Retail, Financial Institutions, Data Center, Schools",
  //     imageSource: process.env.PUBLIC_URL + "/assets/images/logo/market.svg",
  //   },
  //   {
  //     headingname: "Residential & SOHO",
  //     detailsname:
  //       "Complexes, Townships, Small Offices, High-Value Shops, Clubs, Hospitals",
  //     imageSource: process.env.PUBLIC_URL + "/assets/images/logo/house.svg",
  //   },
  //   {
  //     headingname: "Factories, Plants",
  //     detailsname:
  //       "Pharma, Automation industry, Chemical plants, manufacturing units, warehouses",
  //     imageSource: process.env.PUBLIC_URL + "/assets/images/logo/factory.svg",
  //   },
  //   {
  //     headingname: "Government & PSUs",
  //     detailsname:
  //       "Defense, Railways, R&D Establishments, Shipyards, Airports, Ports, Police Stations",
  //     imageSource:
  //       process.env.PUBLIC_URL + "/assets/images/logo/government.svg",
  //   },
  // ];

  return (
    <>
      <section className="application">
        <div className="container">
          <div className="row">
            <div className="heading-about text-center">
              <h4>Application</h4>
            </div>
            <div className="d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>

            {data.map((item, index) => (
              <div
                key={index}
                className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-4"
              >
                <div className="card">
                  <div className="mission text-center">
                    {item.image && (
                      <img
                        src={IMG_URL + item.image}
                        className="commercial-icon"
                        alt={`${item.name}-icon`}
                      />
                    )}
                  </div>
                  <div className="heading-text text-center ">
                    <h4>{item.name}</h4>
                  </div>
                  <div className="text-center text-holder my-3">
                    <p>{parse(item?.description)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Application;

import React from "react";
import "../Products/Products.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const IntrusionSystem = ({ title, imageSrc, description }) => {
  const IntrusionSystemDetails = [
    {
      headingname: "Control Panel",
      detailsname:"You can use Control Panel to change settings for Windows. These settings control nearly ............",
      imageSource:process.env.PUBLIC_URL + "assets/images/Products/intrusion-1.png",
    },
    {
      headingname: "GSM Panel",
      detailsname:"GSM alarm systems can be a great addition to your home security set-up. GSM stands for Global........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/instrusion-2.png",
    },
    {
      headingname: "Hose Reel ",
      detailsname:"A hose reel is a cylindrical spindle made of either metal, fiberglass, or plastic and is used.... ",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/intrusion-3.png",
    },
    {
      headingname: "Sounder",
      detailsname:"Smoke alarms detect fires by sensing small particles in the air using a couple of ......",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/intrusion-4.png" ,
    },
    {
      headingname: "Shop Kit",
      detailsname:"fire extinguisher, portable or movable apparatus used to put out a small fire by directing .......",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/intrusion-5.png",
    },
  ];
  return (
    <>
      <section className="CCTV-System">
        <div className="container">
          <div className="Heading-cctv">
            <div className="heading-about text-center">
              <h4>Intrusion Alarm System</h4>
            </div>
            <div className="borders-three d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
          </div>

          <div className="row justify-content-center">
            {IntrusionSystemDetails.map((item, index) => (
              <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12 mt-4 mt-xl-5 g-5">
                <Card className="IR-Camera mb-3">
                  <Card.Img variant="top" />
                  <div className="Heading-Main">
                    <h1 className="dome-camera">{item.headingname}</h1>
                  </div>

                  <div className="camera-border"></div>
                  <div className="IR-camera-img">
                    <img src={item.imageSource} className="camera-img" />
                  </div>
                  <Card.Body className="IR-Camera-body">
                    <Card.Text>{item.detailsname}</Card.Text>
                    <div className="read-button">
                      <Button variant="read">Read More</Button>
                      <div className="read-border"></div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default IntrusionSystem;

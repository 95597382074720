import React, { useState, useEffect, useContext } from "react";
import "./News.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Context } from "../utils/context";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
const News = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(`/without-login/news/all-news`);
    setData(response.data);

  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  // const NewsDetails = [
  //   {
  //     imageSource:
  //       process.env.PUBLIC_URL + "/assets/images/SupportPage/help.jpg",
  //     heading: "News",
  //     detailstext:
  //       " Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do",
  //   },
  //   {
  //     imageSource:
  //       process.env.PUBLIC_URL + "/assets/images/SupportPage/help.jpg",
  //     heading: "News",
  //     detailstext:
  //       " Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do",
  //   },
  //   {
  //     imageSource:
  //       process.env.PUBLIC_URL + "/assets/images/SupportPage/help.jpg",
  //     heading: "News",
  //     detailstext:
  //       " Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do",
  //   },
  //   {
  //     imageSource:
  //       process.env.PUBLIC_URL + "/assets/images/SupportPage/help.jpg",
  //     heading: "News",
  //     detailstext:
  //       " Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do",
  //   },
  // ];
  return (
    <>
      <section className="News">
        <div className="container">
          <h1 className="news-detail">News And Events</h1>
          <div className="borders d-flex justify-content-center">
            <div className="border-red"></div>
            <div className="border-line "></div>
          </div>
          <div className="row justify-content-center mt-3">
            {data.map((item, index) => (
              <div
                key={item.id}
                className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mt-3"
              >
                <Card className="newsCard">
                  <Card.Img
                    variant="top"
                    src={IMG_URL + item.image}
                    className="Newscard_img"
                  />
                  <Card.Body>
                    <Card.Title>
                      <h1 className="news-heading">{item.name}</h1>
                    </Card.Title>
                    <Card.Text>
                      <p className="text"> {parse(item?.description)}</p>
                    </Card.Text>
                    <div className="Read-btn">
                      <Link variant="read" to={`/details/${item.id}`}>
                        Read More...
                      </Link>
                      <div className="read-border"></div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default News;

import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ThankYou from "./ThankYou/ThankYou";

import { Context } from "../utils/context";
library.add(fas);
const ContactUs = () => {
  const { postData } = useContext(Context);

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const timerRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const handleClose = () => {
    setShow(false);
    //setSubmitAttempted(false);
    // Clear the timer when the modal is closed
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };
  const handleShow = async (e) => {
    e.preventDefault();
    setSubmitAttempted(true);

    // Validate the form fields
    if (validateForm()) {
      // Show success modal
      setShow(true);

      try {
        // Make a POST request to your API endpoint

        const response = await postData(
          "/without-login/contact-us/contact-us",
          formData
        );

        if (response?.success) {
          // Set up the timer when the component is shown
          setShow(true);
          timerRef.current = setTimeout(() => {
            window.location = "/";
            handleClose();
          }, 3000);
        } else {
          alert("Failed to submit the form. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred. Please try again later.");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateForm = () => {
    const errors = {};
    let isValid = true;
    const newValidationMessages = {};

    // Validate name
    // if (!formData.name || !formData.name.trim()) {
    //   errors.name = "Name is required";
    // } else if (!(/^[A-Za-z]+$/).test(formData.name.trim())) {
    //   newValidationMessages.name = "Enter Valid First Name";
    //   isValid = false;
    // }

    if (!formData.name || !formData.name.trim()) {
      errors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.name.trim())) {
      errors.name = "Enter Valid Name";
    }

    // Validate phone (you can add more specific phone number validation if needed)
    if (!formData.phone || !formData.phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(formData.phone.trim())) {
      // Check if the phone number has exactly 10 digits
      errors.phone = "Phone must be a 10-digit number";
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email || !formData.email.trim()) {
      errors.email = "Email is required";
    } else if (
      !/^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
        formData.email.trim()
      )
    ) {
      errors.email = "Valid email is required";
    }

    setErrors(errors);

    // Return true if there are no errors, indicating the form is valid
    return Object.keys(errors).length === 0;
  };

  const validateEmail = (email) => {
    const emailRegex = /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!emailRegex.test(email)) {
      return false; // Invalid email format
    }

    const [, domain] = email.split("@");

    // Check if the domain is already used or if it contains multiple domain segments
    if (usedDomains.has(domain) || domain.split(".").length > 2) {
      return false; // Invalid domain or already used
    }

    // Add the domain to the set to mark it as used
    usedDomains.add(domain);

    return true; // Valid email
  };
  // Initialize the set to store used domains
  const usedDomains = new Set();

  return (
    <>
      <section className="contact-us ">
        <div className="imag-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12 order-2 order-md-1">
              <div className="loate-holder">
                <div className="d-flex contact-sec">
                  <div className="circle-holder text-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-envelope"
                      className="fa-icon mt-2"
                    />
                  </div>
                  <div className="address-holder ms-3">
                    <h4>info@itpulse.in</h4>
                    <h4>sunita@itpulse.in</h4>
                    <h4>accounts@itpulse.in</h4>
                  </div>
                </div>
                <div className="d-flex contact-sec">
                  <div className="circle-holder text-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-phone"
                      className="fa-icon mt-2"
                    />
                  </div>
                  <div className="address-holder ms-3">
                    <h4>+91 8290031000/ 7728855522</h4>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="circle-holder text-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-location-dot"
                      className="fa-icon mt-2"
                    />
                  </div>
                  <div className="address-holder ms-3">
                    <h4>
                      20,Vinayak Nagar, Bohra Ganesh Ji Road,Udaipur-313001
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-12  order-1 order-md-2">
              <div className="form-holder">
                <div className="heading-about ms-3 mb-5">
                  <h4>Send A Message</h4>
                  <div className="d-flex  ">
                    <div className="border-red"></div>
                    <div className="border-line "></div>
                  </div>
                </div>

                <Form>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder=" "
                      required
                      className={`input-mandatory ${
                        submitAttempted && errors.name ? "is-invalid" : ""
                      }`}
                    />
                    {submitAttempted && errors.name && (
                      <span className="invalid-feedback">{errors.name}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder=" "
                      maxLength={13}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");

                        if (e.target.value.length > 10) {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                      }}
                      className={`input-mandatory ${
                        submitAttempted && errors.phone ? "is-invalid" : ""
                      }`}
                    />

                    {submitAttempted && errors.phone && (
                      <span className="invalid-feedback">{errors.phone}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Enter Valid Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder=" "
                      className={`input-mandatory ${
                        submitAttempted && errors.email ? "is-invalid" : ""
                      }`}
                    />
                    {submitAttempted && errors.email && (
                      <span className="invalid-feedback">{errors.email}</span>
                    )}
                  </Form.Group>
                  <div className="button-holder text-center mt-5">
                    <Button variant="dark" type="button" onClick={handleShow}>
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="Contact-PopUp"
      >
        <Modal.Body>
          <div className="circle">
            <ThankYou />
          </div>
          <h1 className="successfully">
            Your Message has been Sent Successfully.{" "}
          </h1>
          <h1 className="Thanks">Thank You!</h1>
        </Modal.Body>
      </Modal>

      {/* mobile view start */}
    </>
  );
};

export default ContactUs;

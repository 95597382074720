import React, { useState, useEffect, useContext } from "react";
import "./Product.css";
import Card from "react-bootstrap/Card";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import parse from "html-react-parser";

const Product = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [productsPerPage, setProductsPerPage] = useState(8);
  const navigate = useNavigate();

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/product-spotlight/all-productspotlight`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  const allProducts = data || []; // Use the fetched data or provide a default empty array
  const totalSlides = Math.ceil(allProducts.length / productsPerPage);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) {
        setProductsPerPage(2);
      } else if (window.innerWidth <= 768) {
        setProductsPerPage(4);
      } else {
        setProductsPerPage(8);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = () => {
    navigate("/cctv-system")
  }

  return (
    <>
      <section className="home-product-section">
        <div className="container">
          <div className="row">
            <div className="heading-holder text-center mb-5">
              <h4>Product Spotlight</h4>
              <p>Unveiling Excellence, Redefining Your Experience</p>
            </div>

            <Swiper
              spaceBetween={10}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{ clickable: true }}
              navigation={{ clickable: true }}
            >
              {(() => {
                const slides = [];

                for (let i = 0; i < totalSlides; i++) {
                  const start = i * productsPerPage;
                  const end = (i + 1) * productsPerPage;
                  const products = allProducts.slice(start, end);

                  slides.push(
                    <SwiperSlide key={i}>
                      <div className="row">
                        {products?.map((spotlight) => (
                          <div key={spotlight.id} className="col-lg-3 col-md-3 col-sm-6">
                            <Link to={spotlight.detailsLink}>
                              <Card className="mb-4">

                                <div className="product-icon text-center" key={spotlight.id}>
                                  <img
                                    src={IMG_URL + spotlight.subcategory?.imagePath}
                                    className="product-img"
                                    alt="..."
                                  />
                                </div>


                                <Card.Body>

                                  <Card.Title key={spotlight.id}>
                                    <h5 className="product-name">{spotlight.subcategory?.name}</h5>
                                  </Card.Title>


                                  <Card.Text>
                                    <Link to={`/cctv-innerpage/${spotlight.subcategory.id}`}>
                                      <p className="get-details">Get Details</p>
                                    </Link>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                  );
                }

                return slides;
              })()}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
import React, { useState, useEffect, useContext } from "react";
import "./Cctvinnerpage.css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../utils/context";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
library.add(fas);

const CctvInnerpage = () => {
  const { id } = useParams();
  const { getData, IMG_URL } = useContext(Context);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [mainImageSrc, setMainImageSrc] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/category/all-subcategorys/${id}`
    );
    setData(response.data);
    if (response.data.length > 0 && !mainImageSrc) {
      setMainImageSrc(
        IMG_URL + response.data[0]?.subcategory_images[0]?.imagePath
      );
    }
  };

  useEffect(() => {
    getDataAll();
  }, [id]);

  const handleMainImageClick = (image) => {
    setMainImageSrc(IMG_URL + image);
  };

  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <section className="cctv-innerpage">
        {data?.map((item, index) => (
          <div className="container">
            <div className="row camera-sec">
              <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12 col-12 pe-0">
                <div className="d-flex">
                  <div className="leftarrowIcon">
                    <FontAwesomeIcon
                      icon="fa-solid fa-chevron-left"
                      className="leftchevronIcon"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <div className="card-sec">
                    {item.subcategory_images.length > 0 && (
                      <div className="product-detail text-center">
                        {mainImageSrc ? (
                          <img
                            src={mainImageSrc}
                            alt={item.name}
                            style={{
                              width: "250px",
                              height: "250px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <p>Error: Image not found</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xxl-8  col-xl-8 col-lg-7 col-md-12 ps-0 ps-md-none">
                <div className="IR-hover-sec ">
                  <div className="IR-heading">
                    <p className="text-start">{item.name}</p>
                  </div>
                  <div className="description">
                    <p className="text-start">{parse(item?.description)}</p>
                  </div>
                </div>
                <div className="camera-slider p-3">
                  <div className="row ">
                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-2">
                      <div
                        className="main-slider-cctv"
                        onClick={() => handleMainSliderClick("prev")}
                      >
                        <div className="arrow-back" />
                      </div>
                    </div>

                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-10 col-8">
                      <Swiper
                        slidesPerView={"auto"}
                        modules={[Navigation, Autoplay]}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                          380: {
                            slidesPerView: 1.5,
                            spaceBetween: 50,
                          },
                          485: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          575: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                          },

                          768: {
                            slidesPerView: 3,
                            spaceBetween: 120,
                          },
                          992: {
                            slidesPerView: 2,
                            spaceBetween: 70,
                          },
                          1024: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                          },
                          1200: {
                            slidesPerView: 2.4,
                            spaceBetween: 10,
                          },
                          1440: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                          },
                          2000: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                          },
                        }}
                        navigation
                        pagination={{ clickable: true }}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,

                          pauseOnMouseEnter: true,
                          loop: true,
                        }}
                        className="mySwiper"
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                      >
                        {item.subcategory_images.map((subcat, index) => (
                          <SwiperSlide>
                            <div
                              className="camera-imgs"
                              onClick={
                                () =>
                                  setMainImageSrc(IMG_URL + subcat.imagePath)
                                // handleMainImageClick(index, subcat.imagePath)
                              }
                            >
                              <img
                                src={IMG_URL + subcat.imagePath}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                                className="inner-img"
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>

                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-2">
                      <div
                        className="main-slider-cctv"
                        onClick={() => handleMainSliderClick("next")}
                      >
                        <div className="arrow-next" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="IR-dome-section">
                <div className="red-line"></div>
                <div className="IR-heading">
                  <p>{item.name}</p>
                </div>
                <div className="description">
                  <p>{parse(item.sub_description)}</p>
                </div>
                <div className="red-line"></div>
              </div>
            </div>

            <div className="cards-section">
              <div className="row justify-content-center">
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12  mx-lg-4 mx-sm-2">
                  <div className="cards">
                    <div className="type-text-sec">
                      <p>Common Type</p>
                    </div>
                    <div className=" row inner-card">
                      <div className="col-md-1 col-1"></div>

                      <div className="col-md-10 col-10">
                        <div className="security">
                          <p>{parse(item?.commontype)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-5 col-sm-12  mx-lg-5 mx-sm-2">
                  <div className="cards mt-md-0 mt-sm-3 mt-3 ">
                    <div className="type-text-sec">
                      <p>Uses</p>
                    </div>
                    <div className=" row inner-card ">
                      <div className="col-md-1 col-1"></div>
                      <div className="col-md-10 col-10">
                        <div className="security">
                          <p>{parse(item?.uses)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default CctvInnerpage;

import React, { useState, useEffect, useContext } from "react";
import "./OurAbout.css";
import { Link } from "react-router-dom";
import ContactUs from "../../contact-us/ContactUs";
import { Context } from "../../utils/context";
import parse from "html-react-parser";
const OurAbout = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [length, setLength] = useState(1);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/home-part-two/all-home-part-two`);
      setData(response.data);
      setLength(response.data.length)
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);
  return (
    <>
      <section className="our-about" id="section1">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7  col-sm-7">
              {data &&
                data?.map((item) => (
                  <div>
                    <div className="heading-holder">
                      <h5 className="mb-3"> About Us</h5>

                      <h4 className="mb-3">
                        {item.name}
                      </h4>
                      {parse(item?.description)}
                      {/* <p className="mb-4">
                        As a one-stop IT solution provider, we emphasize custom
                        requirements, expertise, and savings, ensuring operational
                        efficiency, cost reduction, and excellent customer service.
                      </p> */}
                    </div>
                    {/* <div className="list-holder">
                      <ul className="about-list">
                        <li>
                          <span>Comprising proficient tech expert.</span>
                        </li>
                        <li>
                          <span>Total Enterprise-Wide Solutions.</span>
                        </li>
                        <li>
                          <span>Ongoing Support and Training.</span>
                        </li>
                        <li>
                          <span>
                            Tailoring solutions to address unique project needs.
                          </span>
                        </li>
                        <li>
                          <span>
                            We provide informed and unbiased affordable solutions for
                            every project.
                          </span>
                        </li>
                      </ul>
                    </div> */}

                  </div>))}
              <div className="button-holder mt-lg-4 mt-md-3 mt-3">
                <Link to="/about-us">
                  <button className="btn btn-read">Read More</button>
                </Link>
              </div>
            </div>
            {data &&
              data?.map((item2) => (
                <div className="col-lg-5 col-md-5 col-sm-5">
                  <div className="image-holder text-center">
                    <img
                      src={IMG_URL + item2.image}
                      className="back_img"
                      alt="..."
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurAbout;

import React, { useState, useEffect, useContext } from "react";
import "../AboutUs.css";
import { Context } from "../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
const AboutBanner = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [length, setLength] = useState(1);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/about-us-banner/all-about-us-banner`);
      setData(response.data);
      setLength(response.data.length)
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getHome = async () => {
    try {
      const response = await getData(`/without-login/about-us-description/all-about-us-description`);
      setData2(response.data);
      setLength(response.data.length)
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
      await getHome();
    };
    fetchData();
  }, [0]);

  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  return (
    <>
      <section className="banner-about-us">
        <div className="container-fluid">
          <div className="row">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              centeredSlides={true}


              pagination={{ clickable: true }}
              initialSlide={2}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              //   pauseOnMouseEnter: true,
              //   loop: true,
              // }}
              className="mySwiper"


            >

              {data &&
                data?.map((item) => (
                  <SwiperSlide>
                    <div className="col-md-12 p-0">
                      <img
                        src={IMG_URL + item.image}
                        className="banner-about"
                      />
                    </div>
                  </SwiperSlide>
                ))}


            </Swiper>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-1 col-2 border-red-line"></div>
            <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-10  col-12 px-0">
              <div className="card-box">
                <div className="heading-about text-center">
                  <h4>About us</h4>
                </div>
                <div className="d-flex justify-content-center mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-3">
                  <div className="border-red"></div>
                  <div className="border-line "></div>
                </div>
                {data2 &&
                  data2?.map((item2) => (
                    <div className="paragraph-holder ">

                      {parse(item2?.description)}
                      {/* <p>

                        {(item2?.description)}
                      </p> */}
                      {/* <p>
                        IT Pulse takes advantage of being the ultimate solution for
                        total enterprise-wide e-business and infrastructure
                        solutions under one roof. We have an integrated approach to
                        enable IT technology transformation for all kinds of
                        projects and solve intricate enterprise challenges. We help
                        you stay up in your game with ongoing support, education,
                        training, and systems monitoring services to keep you
                        competitive. We acknowledge that digital transformation
                        always delivers operational efficiencies, cost reduction,
                        and great customer service.
                      </p>
                      <p>
                        We achieve this by emphasizing what we believe are the most
                        important factors: custom requirements, expertise, and
                        savings.
                      </p>
                      <h6 className="mb-4">
                        Custom Requirement:{" "}
                        <span>
                          Every project is unique and so are its project needs.
                          Hence, we tailor every solution to get the optimum
                          solutions to complicated problems.
                        </span>
                      </h6>
                      <h6 className="mb-4">
                        Expertise:{" "}
                        <span>
                          With our deep expertise, our customers know that our
                          recommendations are informed and unbiased.
                        </span>
                      </h6>
                      <h6 className="mb-4">
                        Savings:{" "}
                        <span>
                          We believe in the economics of the scale and propose
                          affordable solutions for every project.
                        </span>
                      </h6>
                      <p>
                        We appreciate our advisory role with our customers and
                        ensure they can count on us from initial set-up to ongoing
                        technical support.
                      </p> */}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-1  col-2 border-red-line"></div>
          </div>
        </div>
      </section >
    </>
  );
};

export default AboutBanner;

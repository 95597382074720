import React, { useState, useEffect, useContext } from "react";
import "../Products/Products.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Context } from "../utils/context";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
const CCTVsystem = () => {
  const { id } = useParams();
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/category/all-categorys/${id}`
    );
    setData(response.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [id]);

  return (
    <>
      <section className="CCTV-System">
        {data.map((item, index) => (
          <div className="container">
            <div className="Heading-cctv">
              <div className="heading-about text-center">
                <h4>{item.name}</h4>
              </div>
              <div className="borders d-flex justify-content-center">
                <div className="border-red"></div>
                <div className="border-line "></div>
              </div>
            </div>

            <div className="row justify-content-center">
              {item.subcategories.map((subcat, index) => (
                <div
                  key={subcat.id}
                  className="col-xxl-4 col-xl-4 col-lg-6 col-md-6  col-12 mt-4 mt-xl-5 g-5"
                >
                  <Card className="IR-Camera mb-3">
                    <Card.Img variant="top" />
                    <div className="Heading-Main">
                      <div className="CCtvcardLine"></div>
                      <h1 className="dome-camera">{subcat.name}</h1>
                    </div>

                    <div className="camera-border"></div>
                    <div className="IR-camera-img">
                      <img
                        src={IMG_URL + subcat.imagePath}
                        className="camera-img"
                        style={{ width: '200px', height: '200px' }}
                      />
                    </div>
                    <Card.Body className="IR-Camera-body">
                      <Card.Text>{parse(subcat?.description)}</Card.Text>
                      <div className="read-button">
                        <Link to={`/cctv-innerpage/${subcat.id}`}>
                          <Button variant="read" className="cctvReadMoreBtn">Read More</Button>
                        </Link>
                        <div className="read-border"></div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default CCTVsystem;

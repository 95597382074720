import React, { useState, useEffect, useContext } from "react";
import "./SupportPage.css";
import Accordion from "react-bootstrap/Accordion";
import MyChatbot from "./MyChatbot/MyChatbot.js";
import { Context } from "../utils/context.js";
import parse from "html-react-parser";
const SupportPage = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/support/all-support`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="Support-Page">
        <div className="container-fluid ms-0 p-0">
          <div className="Support-image">
            <img
              src={
                process.env.PUBLIC_URL +
                "assets/images/SupportPage/IT-Pluse-banner.jpg"
              }
              className="support"
            />
            {/* <div className="support-content">
                <h1 className="help">We Are Here To Support You</h1>
                <p className="support">Get Support Anytime</p>
              </div> */}
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="accordion-content">
                <Accordion defaultActiveKey="0" flush>
                  {data &&
                    data.map((item) => (
                      <Accordion.Item key={item.id} eventKey={item.id}>
                        <Accordion.Header>{item.name}</Accordion.Header>
                        <Accordion.Body>
                          {parse(item?.description)}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
            </div>

            {/* <MyChatbot /> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SupportPage;

import React, { useState, useEffect, useContext } from "react";
import './card.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import { Pagination } from 'swiper/modules';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Context } from '../../utils/context';


const Card = () => {
    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);
    const [length, setLength] = useState(1);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/home-lower/all-home-lower`);
            setData(response.data);
            setLength(response.data.length)
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await getDataAll();
        };
        fetchData();
    }, [0]);


    return (
        <>
            <section className='Hover-cardd-section'>
                <div className='container'>
                    {/* <div className='row'>
                        <div className='col-lg-4'>
                            <div className='main-card'>
                                <div className='card-image-div'>
                                    <img className='card-images' src={process.env.PUBLIC_URL + "/assets/images/cards-hover/card1.jpg"} />
                                    <div className='white-overlay'></div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"

                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            400: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}

                    >
                        {data &&
                            data?.map((item) => (
                                <SwiperSlide>
                                    <div className='main-card'>
                                        <div className='card-image-div'>
                                            <img className='card-images' src={IMG_URL + item.image} />
                                            <div className='white-overlay'></div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}

                        {/* <SwiperSlide>
                            <div className='main-card'>
                                <div className='card-image-div'>
                                    <img className='card-images' src={process.env.PUBLIC_URL + "/assets/images/cards-hover/card2.jpg"} />
                                    <div className='white-overlay'></div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='main-card'>
                                <div className='card-image-div'>
                                    <img className='card-images' src={process.env.PUBLIC_URL + "/assets/images/cards-hover/card3.jpg"} />
                                    <div className='white-overlay'></div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='main-card'>
                                <div className='card-image-div'>
                                    <img className='card-images' src={process.env.PUBLIC_URL + "/assets/images/cards-hover/card1.jpg"} />
                                    <div className='white-overlay'></div>
                                </div>
                            </div>
                        </SwiperSlide> */}

                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Card
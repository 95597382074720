import "./banner/Banner.css";
import Banner from "./banner/Banner";
import OurAbout from "./our-about/OurAbout";
import Solution from "./solution/Solution";
import Product from "./product/Product";
import BEcome from "./Become/BEcome";
import QuickEnquiry from "./quick-enquiry/QuickEnquiry";
import DownloadBrochuer from "./download-brochuer/DownloadBrochuer";
import OurBrand from "./our-brand/OurBrand";
import Demo from "./our-brand/Demo";
import Card from "./card-hover/Card";

function Home() {
  return (
    <>
      <Banner />
      <OurAbout />
      <Solution />
      <Product />
      <BEcome />
      <OurBrand />
      <Card />
      <QuickEnquiry />
      <DownloadBrochuer />
    </>
  );
}

export default Home;

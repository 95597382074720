import React from "react";
import AboutBanner from "./about-us-banner/AboutBanner";
import AboutSlider from "./about-slider/AboutSlider";
import TotalSecurity from "./Total-security/TotalSecurity";
import Objective from "./objective-sec/Objective";
import OurStrength from "./our-strength/OurStrength";

const Aboutus = () => {
  return (
    <>
      <AboutBanner />
      <AboutSlider />
      <TotalSecurity />
      <Objective />
      <OurStrength />
    </>
  );
};

export default Aboutus;

import React from "react";
import "../Products/Products.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const AIDC = ({ title, imageSrc, description }) => {
  const AIDCDetails = [
    {
      headingname: "Cash Drawer",
      detailsname:"a container or part of a cash register a machine for recording sales and keeping money in ......",
      imageSource:process.env.PUBLIC_URL + " assets/images/Products/mmf-cash-drawer.png",
    },
    {
      headingname: "HSM Scanning",
      detailsname:"The Handyscan 3D Scanning Module (HSM)TM has been developed as an add-on software........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/HSM_Scanning.png",
    },
    {
      headingname: "HSM_Mobile_Computer ",
      detailsname:"A hardware security module (HSM) is a physical computing device that safeguards and .......",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/HSM_Mobile_Computer.png",
    },
    {
      headingname: "Billing Machine",
      detailsname:"A billing machine is a machine specifically designed to generate customer invoices. It generates......",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/posiflex-aura-6800-250x250.png" ,
    },
    {
      headingname: "Touch Screen Monitor",
      detailsname:"A touch screen is a computer display screen that is sensitive to human touch, allowing ........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/point-sale-pos-touch-screen-monitors-35784-3025367.png ",
    },
  ];
  return (
    <>
      <section className="CCTV-System">
        <div className="container">
          <div className="Heading-cctv">
            <div className="heading-about text-center">
              <h4>AIDC & POS</h4>
            </div>
            <div className="borders d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
          </div>

          <div className="row justify-content-center">
            {AIDCDetails.map((item, index) => (
              <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12 mt-4 mt-xl-5 g-5">
                <Card className="IR-Camera mb-3">
                  <Card.Img variant="top" />
                  <div className="Heading-Main">
                    <h1 className="dome-camera">{item.headingname}</h1>
                  </div>

                  <div className="camera-border"></div>
                  <div className="IR-camera-img">
                    <img src={item.imageSource} className="camera-img" />
                  </div>
                  <Card.Body className="IR-Camera-body">
                    <Card.Text>{item.detailsname}</Card.Text>
                    <div className="read-button">
                      <Button variant="read">Read More</Button>
                      <div className="read-border"></div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AIDC;

import React, { useState, useEffect, useContext } from "react";
import "../AboutUs.css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Context } from "../../utils/context";
import parse from "html-react-parser";
const TotalSecurity = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/about-us/all-total-security`
    );
    setData(response.data); // Set data to an empty array if response is undefined

  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  // const allSecurity = [
  //   {
  //     toolname: "Home Automation Systems",
  //     detailsname: "Multi Apartment Model, Standalone",
  //     imageSource: process.env.PUBLIC_URL + "assets/images/icon/home.png",
  //   },
  //   {
  //     toolname: "Optic Fiber Solution",
  //     detailsname: "",
  //     imageSource: process.env.PUBLIC_URL + "assets/images/icon/cable.png",
  //   },
  //   {
  //     toolname: "CCTV System",
  //     detailsname: "Analog, IP-based, IP based with Video Analytics.",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/cctv-camera.png",
  //   },
  //   {
  //     toolname: "Access Control System",
  //     detailsname:
  //       "Standalone, Network enabled, TCP/IP based Enterprise level.",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/home-automation.png",
  //   },
  //   {
  //     toolname: "Entry Management System",
  //     detailsname: "Boom Barrier, Bollards, Turnstiles, Gate Automation",
  //     imageSource: process.env.PUBLIC_URL + "assets/images/icon/caution.png",
  //   },
  //   {
  //     toolname: "Metal Detection System",
  //     detailsname: "Hand Held, Door Frame",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/detection-system.png",
  //   },
  //   {
  //     toolname: "Fire Alarm System",
  //     detailsname: "Conventional, Addressable",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/fire-detector.png",
  //   },
  //   {
  //     toolname: "Intrusion Alarm System",
  //     detailsname: "Wired , Wireless",
  //     imageSource: process.env.PUBLIC_URL + "assets/images/icon/fire-alarm.png",
  //   },
  //   {
  //     toolname: "Access Control System",
  //     detailsname:
  //       "Standalone, Network enabled, TCP/IP based Enterprise level.",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/home-automation.png",
  //   },
  //   {
  //     toolname: "Car Parking System",
  //     detailsname: "Standalone, TCP/IP based, RFID based",
  //     imageSource: process.env.PUBLIC_URL + "assets/images/icon/parked-car.png",
  //   },
  //   {
  //     toolname: "DATA & Voice Networking",
  //     detailsname: "",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/environment.png",
  //   },
  //   {
  //     toolname: "Data Center Solution",
  //     detailsname:
  //       "including CCTV, ACS, Fire Alarm, Flooding System (FM 200/NOVAC), Rodent and repellent System,VESDA, Water Leak Detection ",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/detection-system.png",
  //   },
  //   {
  //     toolname: "Intrusion Alarm System",
  //     detailsname: "Wired , Wireless",
  //     imageSource: process.env.PUBLIC_URL + "assets/images/icon/fire-alarm.png",
  //   },
  //   {
  //     toolname: "EPABX, Telecom and  Video Conferencing",
  //     detailsname: "",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/Group-20017.png",
  //   },
  //   {
  //     toolname: "PA System",
  //     detailsname: "",
  //     imageSource:
  //       process.env.PUBLIC_URL + "assets/images/icon/car-speakers.png",
  //   },
  // ];

  const [securityPerPage, setsecurityPerPage] = useState(8);
  const totalSlides = Math.ceil(data.length / securityPerPage);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) {
        setsecurityPerPage(2);
      } else if (window.innerWidth <= 768) {
        setsecurityPerPage(4);
      } else if (window.innerWidth <= 991) {
        setsecurityPerPage(6);
      } else {
        setsecurityPerPage(8);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section className="Total-security">
        <div className="container">
          <div className="row mb-5">
            <div className="heading-about text-center">
              <h4>Total Security</h4>
            </div>
            <div className="d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
            <div className="text-center text-holder my-3">
              <p>Securing the future...</p>
            </div>

            <Swiper
              spaceBetween={10}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{ clickable: true }}
              navigation={{ clickable: true }}
            >
              {(() => {
                const slides = [];

                for (let i = 0; i < totalSlides; i++) {
                  const start = i * securityPerPage;
                  const end = (i + 1) * securityPerPage;
                  const security = data.slice(start, end);

                  slides.push(
                    <SwiperSlide key={i}>
                      <div className="row mb-5">
                        {security?.map((item) => (
                          <div
                            key={item.id}
                            className="col-lg-3 col-md-6 col-sm-6 col-12  mt-4"
                          >
                            <div className="box-circle">
                              <div className="box-circle-two">
                                <img
                                  src={IMG_URL + item.image}
                                  className="tool-icon"
                                  alt={item.name}
                                />
                              </div>
                            </div>
                            <div className="name-holder">
                              <h5 className="text-black Featured_companyName">
                                {item.name}
                              </h5>
                              <p className="Featured_vacancy">
                                {parse(item.description)}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                  );
                }

                return slides;
              })()}
            </Swiper>
          </div>
          <hr />
        </div>
      </section>
    </>
  );
};

export default TotalSecurity;

import React from "react";
import "../Products/Products.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const CarParking = ({ title, imageSrc, description }) => {
  const CarParkingDetails = [
    {
      headingname: "Automatic Ticket Dispensing",
      detailsname:"Automatic Ticket Vending Machine (ATVM) was introduced by Indian Railways to reduce.....",
      imageSource:process.env.PUBLIC_URL + "assets/images/Products/car-1.png",
    },
    {
      headingname: "Car Parking System",
      detailsname:"An automated (car) parking system (APS) is a mechanical system designed to minimize the .... ",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/car-2.png",
    },
    {
      headingname: "Stack Parking ",
      detailsname:"Their main purpose, as you may guess, is to stack one or more vehicles above the ground in a....",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/car-3.png",
    },
    {
      headingname: "Pit Lifting Parking System",
      detailsname:"Pit Puzzle Parking System. Pit-Puzzle System is aimed for optimum use of space in basement.....",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/car-4.png" ,
    },
    {
      headingname: "Vertical Horizontal Parking",
      detailsname:"The equipment is designed with multi-row. An exchange space is set on the ground floor. ........ ",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/car-5.png",
    },
  ];
  return (
    <>
      <section className="CCTV-System">
        <div className="container">
          <div className="Heading-cctv">
            <div className="heading-about text-center">
              <h4>Car Parking System</h4>
            </div>
            <div className="borders-four d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
          </div>

          <div className="row justify-content-center">
            {CarParkingDetails.map((item, index) => (
              <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12 mt-4 mt-xl-5 g-5">
                <Card className="IR-Camera mb-3">
                  <Card.Img variant="top" />
                  <div className="Heading-Main">
                    <h1 className="dome-camera">{item.headingname}</h1>
                  </div>

                  <div className="camera-border"></div>
                  <div className="IR-camera-img">
                    <img src={item.imageSource} className="camera-img" />
                  </div>
                  <Card.Body className="IR-Camera-body">
                    <Card.Text>{item.detailsname}</Card.Text>
                    <div className="read-button">
                      <Button variant="read">Read More</Button>
                      <div className="read-border"></div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CarParking;

import React from 'react'
import './Mission.css'
import Lottie from 'react-lottie';
import * as animationData from './Animation - 1702706019059.json';
const Mission = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='mission-lottey mx-auto'>
                <Lottie className=""
                    options={defaultOptions}
                />
            </div>
        </>
    )
}

export default Mission
import React, { useState, useEffect, useContext } from "react";
import "./NewsDetails.css";
import { Context } from "../utils/context";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
const NewsDetails = () => {
  const { getData, IMG_URL } = useContext(Context);
  const { id } = useParams();
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(`/without-login/news/all-news-events/${id}`);
    // `/without-login/category/all-categorys/${id}`
    setData(response.data);

  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);

  return (
    <>
      <section className="NewsDetailspage">
        <div className="container">
          <div className="row mt-3">
            {data.map((item, index) => (
              <div
                key={item.id}
                className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mt-3 mb-5"
              >
                <img
                  variant="top"
                  className="details-img-class"
                  src={IMG_URL + item.image}
                  style={{ width: "350px", height: "350px" }}
                />
                <div className="mt-3">
                  <h2 className="news-heading">{item.name}</h2>
                </div>
                <div className="mt-3">
                  <p className="text-details">{parse(item?.description)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsDetails;

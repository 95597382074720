import React, { useState, useEffect, useContext } from "react";
import "./Solution.css";
import { Context } from "../../utils/context";
import { useParams } from "react-router-dom";
const Solution = () => {
  const { getData, IMG_URL } = useContext(Context);
  const { number } = useParams();
  const [data, setData] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState();
  const [data5, setData5] = useState();
  const getsolution = async () => {
    try {
      const response = await getData(
        `/without-login/home/all-solution-one/${1}`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getsolution2 = async () => {
    try {
      const response2 = await getData(
        `/without-login/home/all-solution-two/${2}`
      );
      setData2(response2.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getsolution3 = async () => {
    try {
      const response3 = await getData(
        `/without-login/home/all-solution-three/${3}`
      );
      setData3(response3.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getsolution4 = async () => {
    try {
      const response4 = await getData(
        `/without-login/home/all-solution-four/${4}`
      );
      setData4(response4.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getsolution5 = async () => {
    try {
      const response5 = await getData(
        `/without-login/home/all-solution-five/${5}`
      );
      setData5(response5.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    getsolution();
    getsolution2();
    getsolution3();
    getsolution4();
    getsolution5();
  }, []);

  return (
    <>
      <section className="solution">
        <div className="container">
          <div className="row">
            <div className="heading-holder text-center mb-5">
              <h4>Solution</h4>
              <p>
                Unlocking the remedy to elevate your challenges into
                opportunities
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="traffic-holder mb-lg-0 mb-md-0 mb-3">
                <img
                  src={IMG_URL + data?.image}
                  className="traffic_img"

                />
                <div className="traffic-name">
                  <p>{data?.name}</p>
                </div>
                <div className="img-overlay"></div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">

              <div className="traffic-holder mb-3">
                <img
                  src={IMG_URL + data2?.image}
                  className="low-angle"
                  alt="..."
                />
                <div className="traffic-name">
                  <p>{data2?.name}</p>
                </div>
                <div className="img-overlay"></div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="traffic-holder mb-3">
                    <img
                      src={IMG_URL + data3?.image}
                      className="high-voltage"
                      alt="..."
                    />
                    <div className="traffic-name ">
                      <p>{data3?.name}</p>
                    </div>
                    <div className="img-overlay"></div>
                  </div>
                  <div className="traffic-holder mb-lg-0 mb-md-0 mb-3">
                    <img
                      src={IMG_URL + data4?.image}
                      className="highways-high"
                      alt="..."
                    />
                    <div className="traffic-name">
                      <p>{data4?.name}</p>
                    </div>
                    <div className="img-overlay"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="traffic-holder mb-lg-0 mb-md-0 mb-3">
                    <img
                      src={IMG_URL + data5?.image}
                      className="hotel"
                      alt="..."
                    />
                    <div className="traffic-name">
                      <p>{data5?.name}</p>
                    </div>
                    <div className="img-overlay"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;

import React from "react";
import Lottie from "react-lottie";
import "./Downarrow.css";
import * as animationData from "./bMOoA22goI.json";
const DownArrow = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div className="down-icon-banner mx-auto">
        <Lottie className="arrow-icon" options={defaultOptions} />
      </div>
    </>
  );
};

export default DownArrow;

import React from "react";
import "../Products/Products.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const MetalDetection = ({ title, imageSrc, description }) => {
  const MetalDetectionDetails = [
    {
      headingname: "Fan Controls",
      detailsname:"Fan control is the management of the rotational speed of an electric fan. In computers,.....",
      imageSource:process.env.PUBLIC_URL + "assets/images/Products/home-1.png",
    },
    {
      headingname: "Multi Room Control",
      detailsname:"Multi-room audio (distributed audio) systems are audio systems that allow for playback and.....",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/home-2.png",
    },
    {
      headingname: "Remote Control ",
      detailsname:"a remote control is an electronic device used to operate another device from a distance, ...",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/home-3.png",
    },
    {
      headingname: "Video Display",
      detailsname:"Video display means the part of an electronic device capable of presenting images ........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/home-4.png" ,
    },
    {
      headingname: "Outdoor",
      detailsname:"There are three main elements of a home automation system: sensors, controllers, and.......",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/home-5.png ",
    },
  ];
  return (
    <>
      <section className="CCTV-System">
        <div className="container">
          <div className="Heading-cctv">
            <div className="heading-about text-center">
              <h4>Home Automation Systems</h4>
            </div>
            <div className="borders-seven d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
          </div>

          <div className="row justify-content-center">
            {MetalDetectionDetails.map((item, index) => (
              <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12 mt-4 mt-xl-5 g-5">
                <Card className="IR-Camera mb-3">
                  <Card.Img variant="top" />
                  <div className="Heading-Main">
                    <h1 className="dome-camera">{item.headingname}</h1>
                  </div>

                  <div className="camera-border"></div>
                  <div className="IR-camera-img">
                    <img src={item.imageSource} className="camera-img" />
                  </div>
                  <Card.Body className="IR-Camera-body">
                    <Card.Text>{item.detailsname}</Card.Text>
                    <div className="read-button">
                      <Button variant="read">Read More</Button>
                      <div className="read-border"></div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MetalDetection;

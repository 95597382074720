import React from "react";
import "../Products/Products.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
const DigitalNetwork = ({ title, imageSrc, description }) => {
  const DigitalNetworkDetails = [
    {
      headingname: "Network Video Recorder",
      detailsname:"Network Video Recorder (NVR) is a specialized computer that records security video ....",
      imageSource:process.env.PUBLIC_URL + "assets/images/Products/network-1.png",
    },
    {
      headingname: "Embedded Net DVR",
      detailsname:"Embedded DVR is a standalone device which takes the input of the analog camera. It will ........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/network-2.png",
    },
    {
      headingname: "Standlone DVR ",
      detailsname:"A standalone (embedded) DVR is a single unit made up of a cabinet with all the .....",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/network-3.png",
    },
    {
      headingname: "Economic NVR",
      detailsname:"NVR stands for Network Video Recorder. NVRs are a relatively new technology, enabled by........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/network-4.png" ,
    },
    {
      headingname: "High End NVR",
      detailsname:"Compared to an NVR system, most DVR cameras are less complex and expensive........",
      imageSource:process.env.PUBLIC_URL +"assets/images/Products/network-5.png ",
    },
  ];
  return (
    <>
      <section className="CCTV-System">
        <div className="container">
          <div className="Heading-cctv">
            <div className="heading-about text-center">
              <h4>Digital & Network Video Recorders</h4>
            </div>
            <div className="borders-nine d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
          </div>

          <div className="row justify-content-center">
            {DigitalNetworkDetails.map((item, index) => (
              <div key={index} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12 mt-4 mt-xl-5 g-5">
                <Card className="IR-Camera mb-3">
                  <Card.Img variant="top" />
                  <div className="Heading-Main">
                    <h1 className="dome-camera">{item.headingname}</h1>
                  </div>

                  <div className="camera-border"></div>
                  <div className="IR-camera-img">
                    <img src={item.imageSource} className="camera-img" />
                  </div>
                  <Card.Body className="IR-Camera-body">
                    <Card.Text>{item.detailsname}</Card.Text>
                    <div className="read-button">
                      <Button variant="read">Read More</Button>
                      <div className="read-border"></div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default DigitalNetwork;

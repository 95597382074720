import React, { useState, useEffect, useContext } from "react";
import "./OurClient.css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Context } from "../utils/context";
//  const clientImages = [
//   "/assets/images/our-client/client1.png",
// //   "/assets/images/our-client/client2.png",
// //   "/assets/images/our-client/client3.png",
// //   "/assets/images/our-client/client4.png",
// //   "/assets/images/our-client/client5.png",
// //   "/assets/images/our-client/client6.png",
// //   "/assets/images/our-client/client7.png",
// //   "/assets/images/our-client/client8.png",
// //   "/assets/images/our-client/client9.png",
// //   "/assets/images/our-client/client10.png",
// //   "/assets/images/our-client/client11.png",
// //   "/assets/images/our-client/client12.png",
// //   "/assets/images/our-client/client13.png",
// //   "/assets/images/our-client/client14.png",
// //   "/assets/images/our-client/client15.png",
// //   "/assets/images/our-client/client16.png",
// //   "/assets/images/our-client/client17.png",
// //   "/assets/images/our-client/client18.png",
// //   "/assets/images/our-client/client19.png",
// //   "/assets/images/our-client/client20.png",
// //   "/assets/images/our-client/client21.png",
// //   "/assets/images/our-client/client22.png",
// //   "/assets/images/our-client/client23.png",
// //   "/assets/images/our-client/client24.png",
// //   "/assets/images/our-client/client25.png",
// //   "/assets/images/our-client/client26.png",
// //   "/assets/images/our-client/client27.png",
// //   "/assets/images/our-client/client28.png",
// //   "/assets/images/our-client/client29.png",
// //   "/assets/images/our-client/client30.png",
// //   "/assets/images/our-client/client31.png",
// //   "/assets/images/our-client/client32.png",
// //   "/assets/images/our-client/client33.png",
// //   "/assets/images/our-client/client34.png",
// //   "/assets/images/our-client/client35.png",
// //   "/assets/images/our-client/client36.png",
//  ];

const OurClient = () => {


  const { getData, IMG_URL } = useContext(Context);

  const [clientImages, setData] = useState([]);


  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/our-client/all-ourclient`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getDataAll();
  }, [0]);


  const [chunkSize, setChunkSize] = useState(18);
  const [chunkedImages, setChunkedImages] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 380) {
        setChunkSize(2);
      } else if (window.innerWidth < 576) {
        setChunkSize(3);
      }
      else if (window.innerWidth < 768) {
        setChunkSize(6);
      }
      else if (window.innerWidth < 991) {
        setChunkSize(8);
      } else {
        setChunkSize(18);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const chunks = [];
    for (let i = 0; i < clientImages.length; i += chunkSize) {
      chunks.push(clientImages.slice(i, i + chunkSize));
    }
    setChunkedImages(chunks);
  }, [chunkSize, clientImages]);
  return (
    <>
      <section className="our-client">
        <div className="container">
          <div className="row">
            <div className="heading-about text-center">
              <h4>Our Clients</h4>
            </div>
            <div className="d-flex justify-content-center">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
            <div className="col-md-12 ">
              <Swiper
                className="border-slides mt-4"
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{ clickable: true }}
                navigation={{ clickable: true }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  loop: true,
                }}
              >
                {chunkedImages.map((chunk, index) => (
                  <SwiperSlide key={index}>
                    <div className='row  my-5 mx-5  justify-content-center'>
                      {chunk.map((client, clientIndex) => (
                        <div key={clientIndex} className='col-xxl-2 col-xl-2  col-lg-2 col-md-3 col-sm-4  col-12'>
                          <div className='client-brand'>
                            <img
                              className='client-partner'
                              src={IMG_URL + client.image}
                              alt={`Logo ${clientIndex + 1}`}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto ">
              <div className="tiger-overlay">
                <div className="leader-box">
                  <img
                    className="leader-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/our-client/Tiger.png"
                    }
                    alt=""
                  />
                </div>
                <div className="row">
                  <div className="col-xxl-4">
                    <div className="text-overlay">
                      <p className="leader-text ">"A Leader</p>
                      <p className="someone-text ">is Someone who</p>
                      <p className="leader-text1 ">Demonstrates</p>
                      <p className="leader-text2 ">what's possible"</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default OurClient;

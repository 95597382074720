import React, { useEffect, useRef, useState, useContext } from "react";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import DownArrow from "./Animation/DownArrow";
import Typewriter from "typewriter-effect";
import { Context } from "../../utils/context";
import axios from "axios";
import parse from "html-react-parser";
import LazyLoad from "react-lazyload";

function Banner() {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const vidRefs = useRef([]);

  const getDataAll = async () => {
    const response = await getData(`/without-login/home/all-banner`);
    await setData(response.data);

  };

  const handleSwiperInit = (swiper) => {
    swiper.slideTo(2);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, []);

  // const playVideo = (index) => {
  //   const video = vidRefs.current[index];
  //   if (video) {
  //     video.play().catch((error) => {
  //       console.error("Error playing video:", error.message);
  //     });
  //   }
  // };

  // const pauseVideo = (index) => {
  //   if (vidRefs.current[index]) {
  //     vidRefs.current[index].pause();
  //   }
  // };

  return (
    <section className="home-banner">
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-md-12 p-0">
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{ clickable: true }}
              navigation={{ clickable: true }}
              // autoplay={{
              //   delay: 5000,
              //   disableOnInteraction: false,
              //   loop: true,
              // }}
              loop={true}
              onInit={(swiper) => handleSwiperInit(swiper)}
            // onSlideChange={(swiper) => {
            //   pauseVideo(swiper.previousIndex);
            //   playVideo(swiper.activeIndex);
            // }}
            >
              {/* {data &&
                data?.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className="banner-slider desktop-view">
                      <div className="video-container">
                        <LazyLoad>
                          <video
                            className="video-class-grid"
                            src={IMG_URL + slide.image}
                            muted
                            loop
                            style={{ width: "1580px", height: "700px" }}
                          />

                          <img src={process.env.PUBLIC_URL + "/assets/images/banner/About_Us.png"} />



                        </LazyLoad>
                      </div>
                      <div className="img-overlay col-sm-12">
                        <div className="text-holder text-center">
                          <h2>{slide.name}</h2>
                          <p>{parse(slide.description)}</p>
                        </div>
                      </div>
                    </div>

                    <div className="mobile-view">
                      <div className="img-overlay col-sm-12">
                        <div className="text-holder text-center">
                          <h2>
                            {" "}
                            <Typewriter
                              options={{
                                strings: [slide.title],
                                autoStart: true,
                                loop: true,
                              }}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))} */}

              {data &&
                data.map((slide, index) => (
                  <SwiperSlide>
                    <div key={index} className="banner-slider desktop-view p-0">
                      <div className="video-container">
                        {slide.type === "video" ? (
                          <video
                            autoPlay
                            muted
                            loop
                            className="video-class-grid"

                          >
                            <source
                              src={IMG_URL + slide.image}
                              type="video/mp4"
                            />

                          </video>

                        ) : (
                          <img
                            src={IMG_URL + slide.image}
                            className="video-class-grid"
                            alt="slider-img"

                          />
                        )}

                      </div>
                      <div className="img-overlay col-sm-12">
                        <div className="text-holder text-center">
                          <h2>{slide.name}</h2>
                          <p>{parse(slide.description)}</p>
                        </div>
                      </div>

                      {/* <div className="row">
                      <div className="col-md-12 mb-3 mt-5">
                        <div className="heading-holder">
                          <h2 className="book">{slide.titleblur}</h2>
                          <div className="overlay">
                            <p className="room">{slide.subtitle}</p>
                            <h1 className="hostel">{slide.name}</h1>
                            <h1 className="hostel">{slide.title2}</h1>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="room-btn">
                            <button type="button" class="btn btn-primary">
                              ROOM SUITES
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}

                      <div className="overley"></div>
                    </div>


                  </SwiperSlide>
                ))}


              <div
                className="lottey-holder"
                onClick={() => scrollToSection("section1")}
              >
                <DownArrow />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;

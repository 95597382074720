import React from "react";
import "./TermsConditon.css";
const TermsConditon = () => {
  return (
    <>
      <section className="terms-condition">
        <div className="container">
          <div className="row">
            <div className="heading-about text-center ">
              <h4>Terms And Conditions</h4>
            </div>
            <div className="d-flex justify-content-center mb-5">
              <div className="border-red"></div>
              <div className="border-line "></div>
            </div>
            <div className="d-flex">
              <div className="rentangle-box mt-1"> </div>
              <div className="heading-name ms-2">
                <h5>Services & Statements of Work</h5>
              </div>
            </div>
            <div className="paragraph-name ms-md-3 ms-0">
              <p>
                The Services to be covered under this Agreement are set forth in
                one or more purchase orders, other ordering documents, and
                statements of work, together with any exhibits thereto (each a
                "SOW"), which reference this Agreement, the terms and conditions
                of which are incorporated into and made a part of any such SOW.
                In the event Client chooses to order products or Services from
                PC Connection Sales Corporation or any of its Affiliates
                ("Service Provider") utilizing the Internet or Service
                Provider’s website, the terms and conditions contained therein
                shall apply to any such products or Services ordered. The cost,
                installation and functioning of all products not provided by
                Service Provider in rendering Services hereunder, including,
                without limitation, electric power, electrical wiring, cabling,
                telephone equipment, computers, modems, printers, tables, etc.
                shall be Client’s sole responsibility.
              </p>
            </div>

            <div className="d-flex">
              <div className="rentangle-box mt-1"> </div>
              <div className="heading-name ms-2 ">
                <h5>Pricing & Terms of Payment</h5>
              </div>
            </div>
            <div className="d-flex">
              <div className="alphabat-holder">
                <span>a)</span>
              </div>
              <div className="paragraph-name ms-md-1 ms-0">
                <p>
                  The price for Services to be provided will be set forth in a
                  SOW. Service Provider may, after the initial term of a SOW,
                  increase charges for Services by giving the Client thirty (30)
                  days written notice. Notwithstanding the foregoing, Service
                  Provider reserves the right to change the price of Services at
                  any time and in its sole reasonable discretion where factors
                  <br />
                  (i) involving Client’s systems or operating environments or
                  lack thereof
                  <br />
                  (ii) outside of Service Provider’s reasonable control increase
                  the cost to Service Provider.
                </p>
              </div>
            </div>

            <div className="d-flex">
              <div className="alphabat-holder">
                <span>b)</span>
              </div>
              <div className="paragraph-name ms-md-1 ms-0">
                <p>
                  All terms are net 30 days, unless otherwise specified in a
                  SOW, contingent upon Client qualifying for credit with Service
                  Provider. Should Client not qualify for sufficient credit with
                  Service Provider, payment must be made via other acceptable
                  form such as credit card or prepayment. Failure to pay within
                  specified
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="alphabat-holder">
                <span>c)</span>
              </div>
              <div className="paragraph-name ms-md-1 ms-0">
                <p>
                  {" "}
                  terms may at the option of Service Provider result in the
                  suspension of the contract, imposition of interest charges at
                  the rate of a 1.5% per month or the highest allowed by law,
                  whichever is lower, and may result in the termination of the
                  contract by Service Provider upon written notice.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="alphabat-holder">
                <span>d)</span>
              </div>
              <div className="paragraph-name ms-md-1 ms-0">
                <p>
                  {" "}
                  The SOW shall specify whether the contract is based on a fixed
                  price or hourly rate. All reasonable travel, lodging, car
                  rentals, and meal expenses will be billed to the Client unless
                  otherwise specified in SOW.
                  <br />
                  Any sales and use taxes shall be added to the invoice. In the
                  event Client claims exemption from sales and use taxes, Client
                  must provide Service Provider with the appropriate tax
                  exemption certificate from the taxing authority.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="rentangle-box mt-1"> </div>
              <div className="heading-name ms-2 ">
                <h5>Fees & Taxes</h5>
              </div>
            </div>
            <div className="paragraph-name ms-md-3 ms-0">
              <p>
                Client, at its expense, shall pay, discharge, and be responsible
                for, all licensee fees, business, sales, use, or other similar
                taxes or assessments charged or levied by reason of anything
                performed under this Agreement, excluding, however, all taxes
                and assessments applicable to Service Provider’s income or
                applicable to Service Provider’s property. If Service Provider
                is required to remit any fee, tax, or duty on behalf of or for
                the account of Client, Client will reimburse Service Provider
                within ten (10) days after Service Provider notifies Client in
                writing of such remittance.
              </p>
            </div>
            <div className="d-flex">
              <div className="rentangle-box mt-1"> </div>
              <div className="heading-name ms-2 ">
                <h5>Proprietary Rights</h5>
              </div>
            </div>
            <div className="paragraph-name ms-md-3 ms-0">
              <p>
                Except for any deliverables as set forth in a SOW, Service
                Provider does not convey or transfer nor does Client obtain any
                right or interest in any of the software programs, systems,
                tools, data or materials or process utilized or provided by
                Service Provider in connection with the performance of this
                Agreement, including but not limited to the WebSPOC® software.
                Service Provider grants to Client a perpetual, royalty-free,
                worldwide right to use the technology imbedded in the Services.
              </p>
            </div>
            <div className="d-flex">
              <div className="rentangle-box mt-1"> </div>
              <div className="heading-name  ms-2 ">
                <h5>Client Representation</h5>
              </div>
            </div>
            <div className="paragraph-name ms-md-3 ms-0">
              <p>
                Client represents and warrants to Service Provider that Client
                has the right to authorize Service Provider to repair and/or
                Service all items of computer products, hardware or software,
                which are or become subject to this Agreement.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditon;

import React, { useState, useEffect, useContext } from "react";
import "./profile.css";
import { Context } from "../utils/context";
import parse from "html-react-parser";
const Profile = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/director-profile/all-director-profile`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [0]);

  return (
    <>
      <section className="direcrtor-profile-section">
        <div className="container">
          <div className="text-center">
            <h3>Director's Profile</h3>
          </div>

          {data &&
            data?.map((item) => (
              <div className="row profileRow">
                <div className="col-md-3 ">
                  <div className="sec-1">
                    <img className="director-img" src={IMG_URL + item.image} />
                    <div className="sec-2 text-center">
                      <h3 className="">{item.name}</h3>
                      <p className="">{item.position}</p>
                      {/* <p className="description">{parse(item?.description)}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-3  ">
                  <div className="sec-1">
                    <img
                      className="director-img "
                      src={IMG_URL + item.image2}
                    />
                    <div className="sec-2 text-center">
                      <h3 className="">{item.name2}</h3>
                      <p className="">{item.position2}</p>
                      {/* <p className="description">{parse(item?.description)}</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12  mx-auto mt-5">
                  {/* <p className="description">{parse(item?.description)}</p> */}

                  <div className="description">{parse(item?.description)}</div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default Profile;

import React, { useState, useEffect, useContext } from "react";
import "./OurBrand.css";
import { Context } from "../../utils/context";

const OurBrand = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [length, setLength] = useState(1);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/home/all-our-brand`);
      setData(response.data);
      setLength(response.data.length)
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);
  const marqueeBlock = {
    '--total-marquee-items': length,
    height: '150px',
    width: `calc(250px * var(--total-marquee-items))`,
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
    margin: '5px auto',
    padding: '30px 0',
  };


  return (
    <>
      <section className="our-brand-scroll">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="title-our-brand text-center">
                <h2>Our Trusted Brand</h2>
                <p>Explore the top brands across all categories</p>
              </div>
              <div className="marquee-wrapper">
                <div className="container">
                  <div style={marqueeBlock} className="marquee-block">
                    <div className="marquee-inner to-left">
                      <span>
                        {data &&
                          data?.map((item) => (
                            <div className="marquee-item" key={item.id}>
                              <img
                                className="brand-partner tool-icon"
                                src={IMG_URL + item.image}
                                style={{ width: '180px', height: '75px' }}
                              />
                            </div>
                          ))}
                      </span>
                      <span>
                        {data &&
                          data?.map((item) => (
                            <div className="marquee-item" key={item.id}>
                              <img
                                className="brand-partner tool-icon"
                                src={IMG_URL + item.image}
                                style={{ width: '180px', height: '75px' }}
                              />
                            </div>
                          ))}
                      </span>
                    </div>
                  </div>
                  <div style={marqueeBlock} className="marquee-block">
                    <div className="marquee-inner to-right">
                      <span>
                        {data &&
                          data?.map((item) => (
                            <div className="marquee-item" key={item.id}>
                              <img
                                className="brand-partner tool-icon"
                                src={IMG_URL + item.image}
                                style={{ width: '180px', height: '75px' }}
                              />
                            </div>
                          ))}
                      </span>
                      <span>
                        {data &&
                          data?.map((item) => (
                            <div className="marquee-item" key={item.id}>
                              <img
                                className="brand-partner tool-icon"
                                src={IMG_URL + item.image}
                                style={{ width: '180px', height: '75px' }}
                              />
                            </div>
                          ))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurBrand;

import React from 'react'
import './TermsConditon.css'
const PrivacyPolicy = () => {
    return (
        <>
            <section className='terms-condition'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-about text-center '>
                            <h4>Privacy Policy</h4>
                        </div>
                        <div className='d-flex justify-content-center mb-5'>
                            <div className='border-red1'></div>
                            <div className='border-line1 '></div>
                        </div>

                        <div className='d-flex'>
                            <div className='rentangle-box mt-1'> </div>
                            <div className='heading-name ms-2 '>
                                <h5>Purpose </h5>
                            </div>
                        </div>
                        <div className='paragraph-name ms-md-3 ms-0'>
                            <p>This policy establishes an effective, accountable and transparent framework for ensuring compliance with the requirements of the
                                GDPR (General Data Protection Regulation)</p>

                        </div>
                        <div className='d-flex'>
                            <div className='rentangle-box mt-1'> </div>
                            <div className='heading-name ms-2 '>
                                <h5>Scope</h5>
                            </div>
                        </div>
                        <div className='paragraph-namems-md-3 ms-0'>
                            <p>This policy applies to all IT By Design, Inc. (“Company”) employees and all third parties responsible for the processing of personal data on behalf of Company’s services/entities.</p>

                        </div>
                        <div className='d-flex'>
                            <div className='rentangle-box mt-1'> </div>
                            <div className='heading-name ms-2 '>
                                <h5>Policy Statement</h5>
                            </div>
                        </div>
                        <div className='paragraph-name ms-md-3 ms-0'>
                            <p>Company is committed to conducting its business in accordance with all applicable data protection laws and regulations and in line with the highest standards of ethical conduct.<br />
                                This policy sets forth the expected behaviors of Company’s employees and third parties in relation to the collection, use, retention, transfer, disclosure and destruction of any personal data belonging to a Company contact (i.e. the data subject).<br />
                                Personal data is any information (including opinions and intentions) that relates to an identified or identifiable natural person. Personal data is subject to certain legal safeguards and other regulations that impose restrictions on how organizations may process personal data.
                                An organization that handles personal data and makes decisions about its use is known as a Data Controller. Company, as a Data Controller, is responsible for ensuring compliance
                                with the data protection requirements outlined in this policy. Non-compliance may expose Company to complaints, regulatory action, fines and/or reputational damage.<br />

                                Company’s leadership is fully committed to ensuring continued and effective implementation of this policy and expects all Company employees and third parties to share in this commitment.
                                Any breach of this policy will be taken seriously and may result in disciplinary action or business sanction.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy
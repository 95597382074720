import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="footer-section ">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-md-7 col-sm-6">
                    <img
                      className="footlogo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/logo/LOGO_2.png"
                      }
                    />

                    <p className="foot-cont">
                      Magnify your tech journey with our innovative IT
                      Product-as-a-Service solutions.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-5  col-sm-6">
                    <h6 className="footer-menu-title">Quick Links</h6>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-6">
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/">
                            Home
                          </Link>
                        </div>
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/about-us">
                            About Us
                          </Link>
                        </div>
                        {/* <div className="foot-menu-div">
                          <Link className="foot-menu" to="/cctv-system">
                            Products
                          </Link>
                        </div> */}
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/our-client">
                            Our Clients
                          </Link>
                        </div>
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/application">
                            Application
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12  col-sm-6">
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/complaint">
                            Complaint
                          </Link>
                        </div>
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/contact-us">
                            Contact Us
                          </Link>
                        </div>
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/terms-condition">
                            Terms and Condition
                          </Link>
                        </div>
                        <div className="foot-menu-div">
                          <Link className="foot-menu" to="/privacy-policy">
                            Privacy Policy
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-6">
                    <h6 className="footer-menu-title">Connect With Us</h6>

                    <div className="foot-menu-div d-flex">
                      <img
                        className="icccc me-2"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/footer/call.png"
                        }
                      />
                      <Link className="foot-menu" to="/">
                        +91 8290031000/ 7728855522
                      </Link>
                    </div>

                    <div className="foot-menu-div  d-flex">
                      <img
                        className="icccc me-2"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/footer/mail.png"
                        }
                      />
                      <Link className="foot-menu" to="/">
                        info@itpulse.in
                        <br />
                        sunita@itpulse.in <br /> accounts@itpulse.in
                      </Link>
                    </div>

                    <div className="foot-menu-div  d-flex">
                      <img
                        className="icccc me-2"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/footer/location.png"
                        }
                      />
                      <Link className="foot-menu" to="/">
                        20,Vinayak Nagar, Bohra Ganesh Ji Road,Udaipur-313001
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4  col-sm-6">
                    <h6 className="footer-menu-title">Social Links</h6>

                    <div className="d-flex">
                      <div className="foot-menu-div">
                        <Link className="foot-menu" to="">
                          <img
                            className="icccc1"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/social-icon/facebook.png"
                            }
                          />
                        </Link>
                      </div>

                      <div className="foot-menu-div">
                        <Link className="foot-menu" to="">
                          <img
                            className="icccc1"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/social-icon/twitter.png"
                            }
                          />
                        </Link>
                      </div>

                      <div className="foot-menu-div">
                        <Link className="foot-menu" to="">
                          <img
                            className="icccc1"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/social-icon/linkdin.png"
                            }
                          />
                        </Link>
                      </div>

                      <div className="foot-menu-div">
                        <Link className="foot-menu" to="">
                          <img
                            className="icccc1"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/social-icon/instagram.png"
                            }
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ms-0 me-0">
            <div className="col-md-11 mx-auto">
              <div className="bod-bot"></div>

              <div className="copyrights-div">
                <Link to="https://profcymaglobal.com/">
                  <p>
                    {" "}
                    © 2023 It pulse | Developed By Profcyma Solutions Pvt. Ltd.
                  </p>
                </Link>
              </div>
            </div>
          </div>

          <img
            className="foot-layerr"
            src={
              process.env.PUBLIC_URL + "/assets/images/footer/foot-layer.png"
            }
          />
        </div>
      </div>
    </>
  );
};

export default Footer;

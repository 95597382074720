import React, { useState, useEffect, useContext, useRef } from "react";
import "../AboutUs.css";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Context } from "../../utils/context";

const AboutSlider = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);
  const [length, setLength] = useState(1);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/about-us-part-two/all-about-us-part-two`);
      setData(response.data);
      setLength(response.data.length)
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      await getDataAll();
    };
    fetchData();
  }, [0]);
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  return (
    <>
      <section className="about-slider">
        <div className="container">
          <div className="row">

            <div className="slider-started">
              <div className="container">
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={30}
                  centeredSlides={true}
                  modules={[Navigation, Autoplay]}
                  navigation
                  pagination={{ clickable: true }}
                  initialSlide={1}
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                  // autoplay={{
                  //   delay: 2500,
                  //   disableOnInteraction: false,
                  //   pauseOnMouseEnter: true,
                  //   loop: true,
                  // }}
                  className="mySwiper"


                >
                  <div className="row">
                    <div className="col-md-8">
                      {data &&
                        data?.map((item) => (
                          <SwiperSlide>

                            <div className="image-container">
                              <img src={IMG_URL + item.image} className="scroll-img image" />
                              <img src={IMG_URL + item.image} className="blured-img image" />

                              <div className="image-shadow"></div>
                            </div>

                          </SwiperSlide>
                        ))}


                      {/* <SwiperSlide>
                        <div className="image-container">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/banner/banner-1.png"
                            }
                            className="scroll-img"
                          />
                          <div className="image-shadow"></div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="image-container">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/banner/banner-3.png"
                            }
                            className="scroll-img"
                          />
                          <div className="image-shadow"></div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="image-container">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/banner/banner-2.png"
                            }
                            className="scroll-img"
                          />
                          <div className="image-shadow"></div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="image-container">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/banner/banner-1.png"
                            }
                            className="scroll-img"
                          />
                          <div className="image-shadow"></div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="image-container">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/banner/banner-3.png"
                            }
                            className="scroll-img"
                          />
                          <div className="image-shadow"></div>
                        </div>
                      </SwiperSlide> */}
                    </div>
                  </div>
                </Swiper>
              </div>
              <div
                className="main-slider"
                onClick={() => handleMainSliderClick("prev")}
              >
                <div className="arrow-back" />
              </div>
              <div
                className="main-slider"
                onClick={() => handleMainSliderClick("next")}
              >
                <div className="arrow-next" />
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
    </>
  );
};

export default AboutSlider;

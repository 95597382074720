import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { Context } from ".././utils/context";
library.add(fas);

function Header() {
  const { getData, IMG_URL } = useContext(Context);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [scroll, setScroll] = useState(false);

  const [data, setData] = useState([]);
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/category/all-category`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  useEffect(() => {
    getDataAll();
  }, []);

  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };

  return (
    <>
      <section className={`header-main ${scroll ? "scrolled" : ""}`}>
        <div className="container-fluid p-0">
          <Navbar expand="lg" className="">
            <Container className="p-0">
              <Navbar.Brand as={Link} to="/">
                <img
                  className="headlogo"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/logo/LOGO_2.png"
                  }
                  alt="Logo"
                />
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />

              <Navbar.Collapse id="basic-navbar-nav" className="menus-main">
                <Nav className="">
                  <Nav.Link
                    as={Link}
                    to="/"
                    active={activeItem === "home"}
                    onClick={() => updateActiveItem("home")}
                  >
                    <span>Home</span>
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/about-us"
                    active={activeItem === "about-us"}
                    onClick={() => updateActiveItem("about-us")}
                  >
                    <span>About Us</span>
                  </Nav.Link>

                  {/* <NavDropdown
                    active={activeItem === "product"}
                    onClick={() => updateActiveItem("product")}
                    title={
                      <span>
                        {" "}
                        Product <FontAwesomeIcon icon={faChevronDown} />{" "}
                      </span>
                    }
                    id="navbarScrollingDropdown"

                  >
                    <div class="scroller">
                      <div className="row">
                        {data &&
                          data?.map((item) => (
                            <div className="col-xxl-2 col-xl-2 col-lg-3  col-md-4 mb-4  ">
                              <NavDropdown.Item className="drop-main-heading">
                                <Link to="/cctv-system">
                                  <span className="red-borders">
                                    {" "}
                                    {item.name}
                                  </span>
                                </Link>
                              </NavDropdown.Item>

                              <NavDropdown.Item className="inner-link">
                                <p className="content-name ">
                                  <Link to="/cctv-innerpage">
                                    {" "}
                                    IR Dome Camera{" "}
                                  </Link>
                                </p>{" "}
                              </NavDropdown.Item>
                              <p className="content-name">
                                IR Cube Network Camera
                              </p>
                              <p className="content-name">IR Camera</p>
                              <p className="content-name">IR Bullet Camera</p>
                              <p className="content-name">
                                IR speed Dome Camera
                              </p>
                              <div class="vl"></div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </NavDropdown> */}

                  <NavDropdown
                    active={activeItem === "product"}
                    onClick={() => updateActiveItem("product")}
                    title={
                      <span>
                        Product <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                    }
                    className="productMainDrop"
                    id="navbarScrollingDropdown"
                  >
                    <div class="scroller">
                      <div className="row">
                        {data &&
                          data?.map((item, index) => (
                            <>
                              <div
                                className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 mb-4 product-vertical-line"
                                key={item.id}
                              >
                                <div>
                                  <NavDropdown.Item className="drop-main-heading">
                                    <div className="red-borders">
                                      <Link
                                        to={`cctv-system/${item.id}`}
                                        className="nav-link"
                                      >
                                        {item.name}
                                      </Link>
                                    </div>
                                  </NavDropdown.Item>
                                  {item?.subcategories?.map((subcate) => (
                                    <NavDropdown.Item
                                      className="inner-link"
                                      key={subcate.id}
                                    >
                                      <p className="content-name">
                                        <Link
                                          to={`/cctv-innerpage/${subcate?.id}`}
                                        >
                                          {subcate.name}
                                        </Link>
                                      </p>
                                    </NavDropdown.Item>
                                  ))}
                                </div>
                              </div>

                              {++index % 6 == 0 ? (
                                <>
                                  {" "}
                                  <hr />
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </NavDropdown>

                  {/* <Link
                    className="nav-link"
                    as={Link}
                    to="/application"
                    active={activeItem === "application"}
                    onClick={() => updateActiveItem("application")}
                  >
                    <span>Application</span>
                  </Link>

                  <Link
                    className="nav-link"
                    as={Link}
                    to="/our-client"
                    active={activeItem === "our-client"}
                    onClick={() => updateActiveItem("our-client")}
                  >
                    <span>Our Clients</span>
                  </Link>

                  <Link
                    className="nav-link"
                    as={Link}
                    to="/director-profile"
                    active={activeItem === "director-profile"}
                    onClick={() => updateActiveItem("director-profile")}
                  >
                    <span>Director's Profile</span>
                  </Link> */}
                  <Nav.Link
                    as={Link}
                    to="/application"
                    active={activeItem === "application"}
                    onClick={() => updateActiveItem("application")}
                  >
                    <span>Application</span>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/our-client"
                    active={activeItem === "our-client"}
                    onClick={() => updateActiveItem("our-client")}
                  >
                    <span>Our Clients</span>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/director-profile"
                    active={activeItem === "director-profile"}
                    onClick={() => updateActiveItem("director-profile")}
                  >
                    <span>Director's Profile</span>
                  </Nav.Link>

                  <Link to="/contact-us">
                    <button className="contact-btnn btn">Contact Us</button>
                  </Link>
                </Nav>

                <Link>
                  <FontAwesomeIcon
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={handleShow}
                    icon="fa-solid fa-bars"
                  />
                </Link>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        <div
          class="offcanvas menu-off-canva offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-body">
            <div className="row me-0 ms-0">
              <div className="col-md-7 p-0">
                <div className="sec-1"></div>
              </div>
              <div className="col-md-1 p-0">
                <div className="sec-2"></div>
              </div>
              <div className="col-md-4 p-0 ">
                <div className="sec-3">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="extra-menus-div">
                    <Nav.Link
                      className="off-nav-mennn"
                      as={Link}
                      to="/complaint"
                      active={activeItem === "complaint"}
                      onClick={() => updateActiveItem("complaint")}
                    >
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        Complaint
                      </span>
                    </Nav.Link>

                    <Nav.Link
                      className="off-nav-mennn"
                      as={Link}
                      to="/news-details"
                      active={
                        activeItem === "news-details" ||
                        activeItem === "details"
                      }
                      onClick={() => updateActiveItem("news-details")}
                    >
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        News & Events
                      </span>
                    </Nav.Link>

                    <Nav.Link
                      className="off-nav-mennn"
                      as={Link}
                      to="/support"
                      active={activeItem === "support"}
                      onClick={() => updateActiveItem("support")}
                    >
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        Support
                      </span>
                    </Nav.Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mobile-view-header-start */}
      <section className="mobile-view-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2">
              <div className=" my-3  ">
                <button
                  className="btn menuBtn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasmobileapp"
                  aria-controls="offcanvasExample"
                >
                  <FontAwesomeIcon icon="fa-solid fa-bars" />
                </button>
              </div>
            </div>
            <div className="col-10 ">
              <div className="contact-button my-3 text-end">
                <Link to="/contact-us">
                  {" "}
                  <button className="btn contact-btnn ">Contact Us</button>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mobile-offcanvas-start  */}
      <div className="mobile-app-offcanvas">
        <div
          className="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasmobileapp"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <div as={Link} to="/">
              <img
                className="headlogo"
                src={process.env.PUBLIC_URL + "/assets/images/logo/LOGO_2.png"}
                alt="Logo"
              />
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="all-pages">
              <Link to="/">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>Home</p>
                  </div>
                </div>
              </Link>

              <Link to="/about-us">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>About Us</p>
                  </div>
                </div>
              </Link>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Product</Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      <Accordion.Item
                        eventKey="1"
                        className="drop-main-heading"
                      >
                        <Link to="/cctv-system">
                          {" "}
                          <Accordion.Header>
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              CCTV System
                            </span>
                          </Accordion.Header>{" "}
                        </Link>
                        <Accordion.Body>
                          <Link to="/cctv-innerpage">
                            <p
                              className="content-name "
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              IR Dome Camera{" "}
                            </p>
                          </Link>
                          <p className="content-name">IR Cube Network Camera</p>
                          <p className="content-name">IR Camera</p>
                          <p className="content-name">IR Bullet Camera</p>
                          <p className="content-name">IR speed Dome Camera</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item
                        eventKey="2"
                        className="drop-main-heading"
                      >
                        <Link to="/aidc-pos">
                          <Accordion.Header>
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              AIDC & POS
                            </span>
                          </Accordion.Header>{" "}
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">HSM_Mobile_Computer</p>
                          <p className="content-name">HSM Scanning</p>
                          <p className="content-name">Cash Drawer</p>
                          <p className="content-name">Billing Machine</p>
                          <p className="content-name">Touch Screen Monitor</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="3">
                        <Link to="/fire-system">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Fire Systems
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">
                            Conventional Fire Alarms
                          </p>
                          <p className="content-name">Fire Sprinkler</p>
                          <p className="content-name">Fire Extinguisher</p>
                          <p className="content-name">Hose Reel</p>
                          <p className="content-name">Smoke Detector</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="4">
                        <Link to="/pa-system">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              PA System
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">Call Station</p>
                          <p className="content-name">Public Address Speaker</p>
                          <p className="content-name">PA System</p>
                          <p className="content-name">Amplifiers</p>
                          <p className="content-name">PA System</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="5">
                        <Link to="/access-control-system">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Access Control System
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">IP Controller</p>
                          <p className="content-name">Locking Device</p>
                          <p className="content-name">Reader</p>
                          <p className="content-name">Vechile Bareer</p>
                          <p className="content-name">Software</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="6">
                        <Link to="/intrusion-system">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Intrusion Alarm System
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">Control Panel</p>
                          <p className="content-name">GSM Panel</p>
                          <p className="content-name">PIR Motion Sensor</p>
                          <p className="content-name">Sounder</p>
                          <p className="content-name">Shop Kit</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="7">
                        <Link to="/car-parking-system">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Car Parking System
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">
                            Automatic Ticket Dispensing
                          </p>
                          <p className="content-name">
                            Pit Lifting Parking System
                          </p>
                          <p className="content-name">Car Parking System</p>
                          <p className="content-name">Stack Parking</p>
                          <p className="content-name">
                            Vertical Horizontal Parking
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="8">
                        <Link to="/metal-detection">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              {" "}
                              Metal Detection System
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">Metal Detector</p>
                          <p className="content-name">Metal Detector</p>
                          <p className="content-name">Door Metal Detector</p>
                          <p className="content-name">Metal Detector</p>
                          <p className="content-name">Metal Detector</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="9">
                        <Link to="/data-solution">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              {" "}
                              Data Center Solution
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">Data Center Solutions</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="10">
                        <Link to="/home-automation">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              {" "}
                              Home Automation <br /> Systems
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">Fan Controls</p>
                          <p className="content-name">Multi Room Control</p>
                          <p className="content-name">Video Display</p>
                          <p className="content-name">Remote Control</p>
                          <p className="content-name">Outdoor</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="11">
                        <Link to="/currency-counting">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              {" "}
                              Currency Counting <br />
                              Machine & Electronic Safe
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">Bundle Note Counting</p>
                          <p className="content-name">Cash Note Counting</p>
                          <p className="content-name">Fake Note Detector</p>
                          <p className="content-name">Electronic Safe</p>
                          <p className="content-name">Paper Shredder</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion>
                      <Accordion.Item eventKey="12">
                        <Link to="/digital-network">
                          {" "}
                          <Accordion.Header className="drop-main-heading">
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Digital & Network <br /> Video Recorders
                            </span>
                          </Accordion.Header>
                        </Link>
                        <Accordion.Body>
                          <p className="content-name">Economic NVR</p>
                          <p className="content-name">High End NVR</p>
                          <p className="content-name">Standlone DVR</p>
                          <p className="content-name">Network Video Recorder</p>
                          <p className="content-name">Embedded Net DVR</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Link to="/application">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>Application</p>
                  </div>
                </div>
              </Link>

              <Link to="/our-client">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>Our Clients</p>
                  </div>
                </div>
              </Link>

              <Link to="/complaint">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>Complaint</p>
                  </div>
                </div>
              </Link>

              <Link to="/news-details">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>News & Events</p>
                  </div>
                </div>
              </Link>

              <Link to="/director-profile">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>Director's Profile</p>
                  </div>
                </div>
              </Link>

              <Link to="/support">
                <div
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <div className="page-holder ">
                    <p>Support</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
